import React, { useLayoutEffect, useState, useEffect } from 'react'
import { auth_routes, routes } from './routes'
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
// Import scss
import './assets/scss/theme.scss'

import { useDispatch, useSelector } from 'react-redux'
import Layout from './components/layout'
import Dashboard from './screens/dashboard/Dashboard'
import {
  setAccessToken,
  setRefreshToken,
  setUserDetails
} from './store/authentication/Authentication'
import SignIn from './screens/authentication/signin/SignIn'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userData = useSelector(state => state.authentication.userdetails)
  const access_token = useSelector(state => state.authentication.access_token)
  const location = useLocation() // Get the current location
  const setpermission = useSelector(state => state.permissions.setpermission)
  const [s_route, sets_route] = useState(auth_routes)

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(window.navigator.onLine)
    }

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  useEffect(() => {
    if (!isOnline) {
      toast.error(
        'Your Internet is not working. Please check your internet connection.',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        }
      )
      
    }
  }, [isOnline])

  useEffect(() => {
    if (
      auth_routes.some(
        route =>
          route.path === location.pathname &&
          !setpermission &&
          access_token === ''
      )
    ) {
      navigate(location.pathname)
    } else if (
      !setpermission &&
      access_token === '' &&
      (userData === null || Object.keys(userData).length === 0)
    ) {
      dispatch(setUserDetails(null))
      dispatch(setAccessToken(''))
      dispatch(setRefreshToken(''))
      navigate('/signin')
    }
  }, [setpermission, access_token, userData])

  useLayoutEffect(() => {
    let data = routes
    if (
      userData !== null &&
      Object.keys(userData).length !== 0 &&
      access_token !== '' &&
      setpermission
    ) {
      if (userData?.is_onboarded) {
        data.push(routes)
      } else if (access_token !== '' && !userData.is_onboarded) {
        data = [
          {
            path: '/dashboard',
            element: <Navigate to='/ems/employee/UserStepForm' />
          },
          ...data.filter(route => route.path !== '/dashboard')
        ]
      } else {
        data = [
          {
            path: '/dashboard',
            element: <SignIn />
          },
          ...data.filter(route => route.path !== '/dashboard')
        ]
      }
      sets_route(data)
    } else if (
      (access_token === '' || access_token === null) &&
      (userData === null ||
        ((Object.keys(userData).length === 0) !== '' && !setpermission))
    ) {
      sets_route(auth_routes)
    }
  }, [userData, routes, setpermission, access_token])

  // to lock screen by clicking shift + L
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const handleKeyPress = event => {
      // if (event.key === 'L')
      // Window + Shift + L
      if (event.key === 'L' && event.shiftKey && event.metaKey) {
        setIsDisabled(!isDisabled)
        document.body.style.pointerEvents = isDisabled ? 'auto' : 'none'
        document.onkeydown = isDisabled ? null : () => false
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [isDisabled])

  //====================== for protecting the routes path
  const userpermission = useSelector(
    state => state.authentication.userpermission
  )

  useLayoutEffect(() => {
    if (
      !userData?.is_superuser &&
      access_token !== '' &&
      userData?.is_onboarded
    ) {
      const currentRoute = routes.find(
        route => route.path === location.pathname
      )
      if (currentRoute && currentRoute.model) {
        const current_model = currentRoute.model
        const current_sub_model = currentRoute.sub_model
        const page_type = currentRoute.page_type

        // Find a matching permission
        const permission = userpermission.find(
          perm =>
            perm.model === current_model && perm.sub_model === current_sub_model
        )
        if (
          page_type === 'VIEW' &&
          permission &&
          (permission.read || permission.update)
        ) {
          navigate(location.pathname)
        } else if (page_type === 'ADD' && permission && permission.write) {
          navigate(location.pathname)
        } else {
          navigate('/404')
        }
      } else {
        navigate(location.pathname)
      }
    }
  }, [userpermission, userData, access_token])

  //---------------------------end here  --------------------------------------

  console.log('s_routeme kya ha -----', s_route)
  console.log('s_routuserpermission-----', userpermission)

  return (
    <>
      <ToastContainer />
      <Routes>
        {userData != null ? (
          <Route path='/' element={<Layout />}>
            {s_route.map((item, index) => {
              return (
                <Route path={item.path} element={item.element} key={index} />
              )
            })}
          </Route>
        ) : (
          <>
            {s_route.map((item, index) => {
              return (
                <Route path={item.path} element={item.element} key={index} />
                // <Route path={"/"} element={<Navigate to="/signin" />} />
              )
            })}
          </>
        )}
      </Routes>
    </>
  )
}

export default App
