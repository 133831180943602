// src/LoginPage.js
import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  InputGroup,
  InputGroupText
} from 'reactstrap'
import profile from '../../../assets/images/etechcube_logo_cropped.png'
import sign from '../../../assets/images/sign.jpg'
import sign1 from '../../../assets/images/sign1.jpg'
import Designer from '../../../assets/images/Designer.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Helmet } from 'react-helmet'
import * as company_details from '../../../constants/CompanyDetails'
import axios from 'axios'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  setAccessToken,
  setEmployee,
  setRefreshToken,
  setUserDetails,
  setUsername,
  setUserPermission,
  setlogin_time
} from '../../../store/authentication/Authentication'
import { ServerAddress } from '../../../constants/ServerAddress'
import {
  setNavigationList,
  setPermission
} from '../../../store/permissions/Permissions'
import { ColorRing } from 'react-loader-spinner'

const SignIn = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userpermission = useSelector(
    state => state.authentication.userpermission
  )
  const setpermission = useSelector(state => state.permissions.setpermission)

  console.log('userpermission--', setpermission)

  const userData = useSelector(state => state.authentication.userdetails)
  const [client_lat, setclient_lat] = useState('')
  const [client_long, setclient_long] = useState('')
  const [client_ip, setclient_ip] = useState('')
  const [is_mobile, setis_mobile] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [show_loader, setshow_loader] = useState(false)

  const [os, setos] = useState('')
  const [brand, setbrand] = useState('')

  const userpass = useSelector(state => state.authentication.userpass)

  const [showPass, setshowPass] = useState(false)
  const [error, seterror] = useState(false)
  const [location_allow, setlocation_allow] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: ''
    },

    validationSchema: Yup.object({
      username: Yup.string().required('Please Enter Your email'),
      password: Yup.string().required('Please Enter  Your Password')
    }),
    onSubmit: values => {
      log_in(values.username, values.password)
      // if (location_allow === false) {
      //   alert("Please allow Your Location First"); // Need To Add Pop Modal instead
      // } else {
      //   log_in(values.username, values.password);
      // }
    }
  })

  useEffect(() => {
    const fetchIP = () => {
      axios
        .get('https://api.ipify.org/?format=json')
        .then(resp => {
          console.log('resp---', resp)
        })
        .catch(error => {
          console.error('Axios network error:', error)
        })
    }

    const getPreciseLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setclient_lat(position.coords.latitude)
            setclient_long(position.coords.longitude)
          },
          error => {
            // Handle the case where location access is denied
            switch (error.code) {
              case error.PERMISSION_DENIED:
                // Handle the case where the user denied location access
                setlocation_allow(false)
                break
              case error.POSITION_UNAVAILABLE:
                // Handle the case where the device can't determine the location
                setlocation_allow(false)
                break
              case error.TIMEOUT:
                // Handle the case where the geolocation request times out
                setlocation_allow(false)
                break
              default:
                setlocation_allow(false)
            }
          }
        )
      } else {
        // Handle the case where geolocation is not supported
        setlocation_allow(false)
      }
    }

    const userAgentData = navigator.userAgentData
    setis_mobile(userAgentData?.mobile || false)
    setos(userAgentData?.platform || '')

    fetchIP()
    getPreciseLocation()
  }, [])

  const getUserDetails = (id, passw, accessToken) => {
    // alert("usern");
    axios
      .get(ServerAddress + 'get_user_details/' + id, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })

      .then(resp => {
        dispatch(setUserDetails(resp.data))

        let user_permissions = []
        // if (resp.data.user_permissions.length > 0) {
        //   user_permissions = resp.data.user_permissions.map((v) => v.name);
        // }
        // dispatch(setUserPermissions(user_permissions));
        // setMenus(userpermission);
      })
      .catch(err => {
        alert(`Error Occur While Getting User Details, ${err}`)
      })
  }

  const getUserPermission = (usern, accessToken) => {
    axios
      .get(ServerAddress + 'ems/get_userpermission/?username=' + usern, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then(resp => {
        dispatch(setPermission(true))
        dispatch(setUserPermission(resp.data.permission))
      })
      .catch(err => {
        alert(`Error Occur While Getting User Permission, ${err}`)
      })
  }
  const [id, setId] = useState(0)

  const log_in = (username, password) => {
    setshow_loader(true)
    axios
      .post(ServerAddress + 'api/login_user_details/', {
        username: username,
        password: password,
        user_ip: client_ip,
        user_lat: client_lat,
        user_long: client_long,
        is_mobile: is_mobile,
        os: os
      })
      .then(function (response) {
        console.log('response', response)
        if (response.data.message == 'Please verify your account first!') {
          setshow_loader(false)
          showToastMessage()
        }
        if (response.status === 200) {
          setshow_loader(false)
          dispatch(setUsername(response.data.user_email))
          dispatch(setEmployee(response.data.employee))
          setUsername(response.data.user_email)
          const currentTime = new Date().toLocaleString('en-GB')
          dispatch(setlogin_time(currentTime))

          // getUserDetails(username, password, response.data.access);
          // getUserPermission(username, response.data.access);
          // send_login_details(username, response.data.access);
          if (response.data.first_login === 'True') {
            setshow_loader(false)
            navigate('/resetpassword')
          } else {
            setshow_loader(false)
            getUserDetails(
              response.data.user_id,
              password,
              response.data.access
            )
            getUserPermission(username, response.data.access)
          }
          dispatch(setAccessToken(response.data.access))
          dispatch(setRefreshToken(response.data.refresh))
        }
      })
      .catch(function (error) {
        setshow_loader(false)
        seterror(true)
      })
  }

  useLayoutEffect(() => {
    if (
      userpermission?.length > 0 &&
      userData &&
      userData.is_superuser === false
    ) {
      let navigation_list = []

      //Org
      let org = []
      let org_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'ORGANIZATION' && model === 'ORGANIZATION'
      )

      if (org_data?.sub_model === 'ORGANIZATION' && org_data?.read === true) {
        org.push(['Organization', '/screens/organisation'])
      }
      //------------------
      let depart_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'DEPARTMENT' && model === 'ORGANIZATION'
      )
      if (
        depart_data?.sub_model === 'DEPARTMENT' &&
        depart_data?.read === true
      ) {
        org.push([
          'Department',
          '/screens/organisation/department/Department.js'
        ])
      }
      //------------------
      let desig_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'DESIGNATION' && model === 'ORGANIZATION'
      )
      if (
        desig_data?.sub_model === 'DESIGNATION' &&
        desig_data?.read === true
      ) {
        org.push(['Designations', '/organisation/designation/Designation'])
      }
      //------------------
      let rol_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'ROLE' && model === 'ORGANIZATION'
      )
      if (rol_data?.sub_model === 'ROLE' && rol_data?.read === true) {
        org.push(['Role', '/role/Role'])
      }
      //------------------

      let holidays_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'HOLIDAYS' && model === 'ORGANIZATION'
      )
      if (
        holidays_data?.sub_model === 'HOLIDAYS' &&
        holidays_data?.read === true
      ) {
        org.push(['Holidays', '/organisation/holidays/HolidayMain'])
      }

      //------------------

      let salary_comp_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'SALARY COMPONENT' && model === 'ORGANIZATION'
      )
      if (
        salary_comp_data?.sub_model === 'SALARY COMPONENT' &&
        salary_comp_data?.read === true
      ) {
        org.push([
          'Salary Component',
          '/screens/organisation/salaryParameter/SalaryComponentList.js'
        ])
      }

      //------------------

      let Permission = userpermission.find(
        ({ sub_model, model }) => sub_model === 'PERMISSION'
      )
      if (Permission?.sub_model === 'PERMISSION' && Permission?.read === true) {
        org.push(['Permission', '/ems/permission'])
      }

      //------------------
      let project_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'PROJECT'
      )
      if (
        project_data?.sub_model === 'PROJECT' &&
        project_data?.read === true
      ) {
        org.push(['Project', '/ems/project'])
      }

      //------------------
      let hierarchy_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'HIERARCHY' && model === 'ORGANIZATION'
      )
      if (
        hierarchy_data?.sub_model === 'HIERARCHY' &&
        hierarchy_data?.read === true
      ) {
        org.push(['Hierarchy', '/organisation/hierarchy/Hierarchy'])
      }

      //------------------
      if (org.length > 0) {
        navigation_list.push({
          id: 0,
          dropdown: 'Organization',
          dropdownMenu: org,
          trigger: false
        })
      }
      //==========================Org end===========================================

      //==========================HR=======================
      let hr = []
      let employees_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'EMPLOYEES' && model === 'HR'
      )
      if (
        employees_data?.sub_model === 'EMPLOYEES' &&
        employees_data?.read === true
      ) {
        hr.push(['Employees', '/hr/employees/Employee'])
      }
      //-------------------------
      let invite_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'INVITE USER' && model === 'HR'
      )
      if (
        invite_data?.sub_model === 'INVITE USER' &&
        invite_data?.read === true
      ) {
        hr.push(['Invite User', '/hr/InviteUser'])
      }
      //-------------------------
      let att = userpermission.find(
        ({ sub_model, model }) => sub_model === 'ATTENDANCE' && model === 'HR'
      )
      if (att?.sub_model === 'ATTENDANCE' && att?.read === true) {
        hr.push(['Attendance', '/hrattendance/Hrattenance'])
      }
      //-------------------------
      let lea = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'LEAVE MANAGEMENT' && model === 'HR'
      )
      if (lea?.sub_model === 'LEAVE MANAGEMENT' && lea?.read === true) {
        hr.push(['Leave Management', '/hrleave/Hrleave'])
      }
      //------------------
      let payslip_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'PAY SLIP' && model === 'HR'
      )
      if (
        payslip_data?.sub_model === 'PAY SLIP' &&
        payslip_data?.read === true
      ) {
        hr.push(['Pay Slip', '/hrpayslip/HrPayslip'])
      }
      //-------------------------
      let offer_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'OFFER LETTER TEMPLATES' && model === 'HR'
      )
      if (
        offer_data?.sub_model === 'OFFER LETTER TEMPLATES' &&
        offer_data?.read === true
      ) {
        hr.push(['Offer Letter Templates', '/OfferLetterTemplates/OfferLetter'])
      }

      //---------- Team
      let team = userpermission.find(
        ({ sub_model, model }) => sub_model === 'TEAM' && model === 'HR'
      )
      if (team?.sub_model === 'TEAM' && team?.read === true) {
        hr.push(['Team', '/team/Team'])
      }
      //---------- change
      let admin = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'ADMIN CHANGE PASSWORD' && model === 'HR'
      )
      if (
        admin?.sub_model === 'ADMIN CHANGE PASSWORD' &&
        admin?.read === true
      ) {
        hr.push(['Admin Change Password', '/ems/adminChangePassword'])
      }
      //---------- Team
      let Correction_req = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'CORRECTION REQUEST' && model === 'HR'
      )
      if (
        Correction_req?.sub_model === 'CORRECTION REQUEST' &&
        Correction_req?.read === true
      ) {
        hr.push(['Correction Request', '/Hrcorrection'])
      }

      //--------------------------
      if (hr.length > 0) {
        navigation_list.push({
          id: 1,
          dropdown: 'HR',
          dropdownMenu: hr,
          trigger: false
        })
      }
      //====================HR End=========================================

      //==========================EMS Start=================
      let ems = []
      let ems_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'LOGIN DETAILS' && model === 'EMS'
      )
      if (ems_data?.sub_model === 'LOGIN DETAILS' && ems_data?.read === true) {
        ems.push(['Login Details', '/ems/logindetails/logindetails'])
      }
      //------------------
      let user_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'USERS' && model === 'EMS'
      )
      if (user_data?.sub_model === 'USERS' && user_data?.read === true) {
        ems.push(['Users', '/ems/users'])
      }
      //------------------
      let attendance_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'ATTENDANCE' && model === 'EMS'
      )
      if (
        attendance_data?.sub_model === 'ATTENDANCE' &&
        attendance_data?.read === true
      ) {
        ems.push(['Attendance', '/hr/EmpAttendance'])
      }
      //------------------
      let leave_apply_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'LEAVE MANAGEMENT' && model === 'EMS'
      )
      if (
        leave_apply_data?.sub_model === 'LEAVE MANAGEMENT' &&
        leave_apply_data?.read === true
      ) {
        ems.push(['Leave Management', '/ems/leave'])
      }
      //------------------
      let payslip = userpermission.find(
        ({ sub_model, model }) => sub_model === 'PAY SLIP' && model === 'EMS'
      )
      if (payslip?.sub_model === 'PAY SLIP' && payslip?.read === true) {
        ems.push(['Pay Slip', '/ems/payroll/payrollSlip/PaySlip'])
      }

      //------------------
      let task_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'TASK TRACKER' && model === 'EMS'
      )
      if (task_data?.sub_model === 'TASK TRACKER' && task_data?.read === true) {
        ems.push(['Task Tracker', '/ems/DailyTask'])
      }
      //------------------
      let assign_task_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'ASSIGN WORK' && model === 'EMS'
      )
      if (assign_task_data?.sub_model === 'ASSIGN WORK' && assign_task_data?.read === true) {
        ems.push(['Assign Work', '/UserAssignedTask'])
      }
      //------------------
      let feedback_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'FEEDBACK' && model === 'EMS'
      )
      if (feedback_data?.sub_model === 'FEEDBACK' && feedback_data?.read === true) {
        ems.push(['Feedback', '/UserFeedback'])
      }
      //------------------
      let Claims = userpermission.find(
        ({ sub_model, model }) => sub_model === 'CLAIMS' && model === 'EMS'
      )
      if (Claims?.sub_model === 'CLAIMS' && Claims?.read === true) {
        ems.push(['Claims', '/ems/claims'])
      }
      //--------------------
      if (ems.length > 0) {
        navigation_list.push({
          id: 2,
          dropdown: 'EMS',
          dropdownMenu: ems,
          trigger: false
        })
      }
      //=====================Ems END==========================================

      //=====================Master Start============
      let master = []

      let branch_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'BRANCHES' && model === 'MASTER'
      )
      if (branch_data?.sub_model === 'BRANCHES' && branch_data?.read === true) {
        master.push(['Branch', '/master/branches'])
      }
      //-------------------
      let location_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'LOCATIONS' && model === 'MASTER'
      )
      if (
        location_data?.sub_model === 'LOCATIONS' &&
        location_data?.read === true
      ) {
        master.push(['Locations', '/master/locations'])
      }
      //---------------------
      let vendor_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'VENDOR' && model === 'MASTER'
      )
      if (vendor_data?.sub_model === 'VENDOR' && vendor_data?.read === true) {
        master.push(['Vendor', '/master/vendor/Vendor'])
      }
      //---------------------
      if (master.length > 0) {
        navigation_list.push({
          id: 3,
          dropdown: 'Master',
          dropdownMenu: master,
          trigger: false
        })
      }

      // ======================Master END=============================

      //====================Support start========================
      let support = []

      let issue_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'RAISE ISSUE' && model === 'SUPPORT'
      )
      if (
        issue_data?.sub_model === 'RAISE ISSUE' &&
        issue_data?.read === true
      ) {
        support.push(['Raise Issue', '/support/RaiseIssue'])
      }
      //------------------------------
      let report_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'REPORT' && model === 'SUPPORT'
      )
      if (report_data?.sub_model === 'REPORT' && report_data?.read === true) {
        support.push(['Report', '/support/report/IssueReport'])
      }
      //-----------------------------
      if (support.length > 0) {
        navigation_list.push({
          id: 4,
          dropdown: 'Support',
          dropdownMenu: support,
          trigger: false
        })
      }
      //====================My Team start========================
      let Team = []

      let team_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'TEAM' && model === 'MY TEAM'
      )
      if (team_data?.sub_model === 'TEAM' && team_data?.read === true) {
        Team.push(['Team', '/myteam/MyTeam'])
      }
      //------------------------------
      let leave_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'LEAVE MANAGEMENT' && model === 'MY TEAM'
      )
      if (
        leave_data?.sub_model === 'LEAVE MANAGEMENT' &&
        leave_data?.read === true
      ) {
        Team.push(['Leave Management', '/myteam/TeamLeave'])
      }
      //-----------------------------
      let Attendance = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'ATTENDANCE' && model === 'MY TEAM'
      )
      if (Attendance?.sub_model === 'ATTENDANCE' && Attendance?.read === true) {
        Team.push(['Attendance', '/myteam/TeamAttendance'])
      }
     
      //---------------------
      let assign_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'ASSIGN WORK' && model === 'MY TEAM'
      )
      if (
        assign_data?.sub_model === 'ASSIGN WORK' &&
        assign_data?.read === true
      ) {
        Team.push(['Assign Work', '/AssignTask'])
      }
      //---------------------
      let review = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'FEEDBACK' && model === 'MY TEAM'
      )
      if (
        review?.sub_model === 'FEEDBACK' &&
        review?.read === true
      ) {
        Team.push(['Feedback', '/Review'])
      }
      //---------------------
      let performance = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'PERFORMANCE' && model === 'MY TEAM'
      )
      if (
        performance?.sub_model === 'PERFORMANCE' && performance?.model === 'MY TEAM' &&
        performance?.read === true
      ) {
        Team.push(['Performance', '/Performance'])
      }
      //---------------------
      let correct_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'CORRECTION REQUEST' && model === 'MY TEAM'
      )
      if (
        correct_data?.sub_model === 'CORRECTION REQUEST' &&
        correct_data?.read === true
      ) {
        Team.push(['Correction Request', '/TeamCorrectionRequest'])
      }

      //-----------------------------
      if (Team?.length > 0) {
        navigation_list?.push({
          id: 4,
          dropdown: 'My Team',
          dropdownMenu: Team,
          trigger: false
        })
      }
      //====================================== Blog Section
      let Blog = []

      let blog_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'BLOG' && model === 'BLOG'
      )
      if (blog_data?.sub_model === 'BLOG' && blog_data?.read === true) {
        Blog.push(['Blog', '/blog'])
      }
      // ------------------------------------------------------------

      let request_data = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'REQUESTED DEMO' && model === 'BLOG'
      )
      if (
        request_data?.sub_model === 'REQUESTED DEMO' &&
        request_data?.read === true
      ) {
        Blog.push(['Requested Demo', '/RequestedDemo'])
      }
      // ------------------------------------------------------------

      let Subscriber_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'SUBSCRIBER' && model === 'BLOG'
      )
      if (
        Subscriber_data?.sub_model === 'SUBSCRIBER' &&
        Subscriber_data?.read === true
      ) {
        Blog.push(['Subscriber', '/Subscriber'])
      }
      // ------------------------------------------------------------

      let get_in_touch = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'GET IN TOUCH' && model === 'BLOG'
      )
      if (
        get_in_touch?.sub_model === 'GET IN TOUCH' &&
        get_in_touch?.read === true
      ) {
        Blog.push(['Get In Touch', '/GetInTouch'])
      }
      // ---------------------------------------------
      if (Blog.length > 0) {
        navigation_list.push({
          id: 6,
          dropdown: 'Blog',
          dropdownMenu: Blog,
          trigger: false
        })
      }
      //====================================== POLICY Section
      let Policy = []

      let policy_data = userpermission.find(
        ({ sub_model, model }) => sub_model === 'POLICY' && model === 'POLICY'
      )
      if (policy_data?.sub_model === 'POLICY' && policy_data?.read === true) {
        Policy.push(['Policy', '/Policy'])
      }

      let view_policy = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'VIEW POLICY' && model === 'POLICY'
      )
      if (
        view_policy?.sub_model === 'VIEW POLICY' &&
        view_policy?.read === true
      ) {
        Policy.push(['View Policy', '/ViewPolicy'])
      }
      // ------------------------------------------------------------

      if (Policy.length > 0) {
        navigation_list.push({
          id: 7,
          dropdown: 'Policy',
          dropdownMenu: Policy,
          trigger: false
        })
      }
      //====================================== CRM Section
      let CRM = []

      let deals = userpermission.find(
        ({ sub_model, model }) => sub_model === 'DEALS' && model === 'CRM'
      )
      if (deals?.sub_model === 'DEALS' && deals?.read === true) {
        CRM.push(['Deals', '/crm/deals/Deals'])
      }
      // ------------------------------------------------------------

      let CustomDND = userpermission.find(
        ({ sub_model, model }) => sub_model === 'CUSTOMDND' && model === 'CRM'
      )
      if (CustomDND?.sub_model === 'CUSTOMDND' && CustomDND?.read === true) {
        CRM.push(['CustomDND', '/crm/customDND/CustomDND'])
      }
      let COMPANY = userpermission.find(
        ({ sub_model, model }) => sub_model === 'COMPANY' && model === 'CRM'
      )
      if (COMPANY?.sub_model === 'COMPANY' && COMPANY?.read === true) {
        CRM.push(['Company Info', '/Company'])
      }
      // ----------------------
      let contectperson = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'CONTACT PERSON' && model === 'CRM'
      )
      if (
        contectperson?.sub_model === 'CONTACT PERSON' &&
        contectperson?.read === true
      ) {
        CRM.push(['Contact Person', '/ContectPerson'])
      }
      //
      // ---------------------------------------------
      if (CRM.length > 0) {
        navigation_list.push({
          id: 8,
          dropdown: 'CRM',
          dropdownMenu: CRM,
          trigger: false
        })
      }
      //====================================== RESIGNATION Section
      let RESIGNATION = []

      let reg = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'RESIGNATION' && model === 'RESIGNATION'
      )
      if (reg?.sub_model === 'RESIGNATION' && reg?.read === true) {
        RESIGNATION.push(['Resignation', '/Resignation/ApplyResignation'])
      }
      // ---------------------------------------------
      if (RESIGNATION.length > 0) {
        navigation_list.push({
          id: 9,
          dropdown: 'Resignation',
          dropdownMenu: RESIGNATION,
          trigger: false
        })
      }
      //====================================== Correction Section
      let Correction = []

      let cor_req = userpermission.find(
        ({ sub_model, model }) =>
          sub_model === 'CORRECTION REQUEST' && model === 'CORRECTION REQUEST'
      )
      if (
        cor_req?.sub_model === 'CORRECTION REQUEST' &&
        cor_req?.read === true
      ) {
        Correction.push(['Correction Request', '/correction/Correction'])
      }
      // ---------------------------------------------
      if (Correction.length > 0) {
        navigation_list.push({
          id: 10,
          dropdown: 'Correction Request',
          dropdownMenu: Correction,
          trigger: false
        })
      }

      // --------------------------end --------------------------
      dispatch(setNavigationList(navigation_list))
      dispatch(setPermission(true))
    }
  }, [userpermission, userData])

  useEffect(() => {
    if (userData && userData.is_superuser === true) {
      let navigation_list = []
      navigation_list.push(
        {
          id: 0,
          dropdown: 'Organization',
          dropdownMenu: [
            ['Organization', '/screens/organisation'],
            ['Department', '/screens/organisation/department/Department.js'],
            ['Designations', '/organisation/designation/Designation'],
            ['Role', '/role/Role'],
            ['Holidays', '/organisation/holidays/HolidayMain'],
            [
              'Salary Component',
              '/screens/organisation/salaryParameter/SalaryComponentList.js'
            ],
            ['Permission', '/ems/permission'],
            ['Project', '/ems/project'],
            ['Hierarchy', '/organisation/hierarchy/Hierarchy']
          ],
          trigger: false
        },
        {
          id: 1,
          dropdown: 'HR',
          dropdownMenu: [
            ['Employees', '/hr/employees/Employee'],
            ['Invite User', '/hr/InviteUser'],
            ['Attendance', '/hrattendance/Hrattenance'],
            ['Leave Management', '/hrleave/Hrleave'],
            ['PaySlip', '/hrpayslip/HrPayslip'],
            ['Offer Letter Templates', '/OfferLetterTemplates/OfferLetter'],
            ['Team', '/team/Team'],
            ['Admin Change Password', '/ems/adminChangePassword'],
            ['Correction Request', '/Hrcorrection']
          ],
          trigger: false
        },

        {
          id: 2,
          dropdown: 'EMS',
          dropdownMenu: [
            ['Login Details', '/ems/logindetails/logindetails'],
            ['Users', '/ems/users'],
            ['Attendance', '/hr/EmpAttendance'],
            ['Leave Management', '/ems/leave'],
            ['PaySlip', '/ems/payroll/payrollSlip/PaySlip'],
            ['Task Tracker', '/ems/DailyTask'],
            ['Assign Work', '/UserAssignedTask'],
            ['Claims', '/ems/claims']
          ],
          trigger: false
        },
        // ---------------------
        {
          id: 3,
          dropdown: 'Master',
          dropdownMenu: [
            ['Branches', '/master/branches'],
            ['Locations', '/master/locations'],
            ['Vendor', '/master/vendor/Vendor']
          ],
          trigger: false
        },
        {
          id: 4,
          dropdown: 'Support',
          dropdownMenu: [
            ['Raise Issue', '/support/RaiseIssue'],
            ['Report', '/support/report/IssueReport']
          ],
          trigger: false
        },
        {
          id: 5,
          dropdown: 'My Team',
          dropdownMenu: [
            ['Team', '/myteam/MyTeam'],
            ['Leave Management', '/myteam/TeamLeave'],
            ['Attendance', '/myteam/TeamAttendance'],
            ['Performance', '/Performance'],
            ['Assign Work', '/AssignTask'],
            ['Feedback', '/Review'],
            ['Correction Request', '/TeamCorrectionRequest'],
          ],
          trigger: false
        },
        {
          id: 6,
          dropdown: 'Blog',
          dropdownMenu: [
            ['Blog', '/blog'],
            ['Requested Demo', '/RequestedDemo'],
            ['Subscriber', '/Subscriber'],
            ['Get In Touch', '/GetInTouch']
          ],
          trigger: false
        },
        {
          id: 7,
          dropdown: 'Policy',
          dropdownMenu: [
            ['Policy', '/Policy'],
            ['View Policy', '/ViewPolicy']
          ],
          trigger: false
        },

        {
          id: 8,
          dropdown: 'CRM',
          dropdownMenu: [
            // ["DND", "/crm/DragAndDropList"],
            ['Contact Person', '/ContectPerson'],
            ['Company Info', '/Company'],
            ['Deals', '/crm/deals/Deals'],
            // ["DEMO", "/crm/demo/Demo"],
            // ["DND1" , "/crm/DND/Ground"],
            ['CustomDND', '/crm/customDND/CustomDND']
          ],
          trigger: false
        },
        {
          id: 9,
          dropdown: 'Resignation',
          dropdownMenu: [['Resignation', '/Resignation/ApplyResignation']],
          trigger: false
        },
        {
          id: 10,
          dropdown: 'Correction Request',
          dropdownMenu: [['Correction Request', '/correction/Correction']],
          trigger: false
        }
      )
      dispatch(setNavigationList(navigation_list))
      dispatch(setPermission(true))
    }
  }, [userData, userpermission, setpermission])

  const showToastMessage = () => {
    toast.warning('Enter Valid Credentials', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
      hideProgressBar: true
    })
  }

  return (
    <>
      {/* loader for avoid multipule click */}
      {show_loader ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '999'
          }}
          aria-labelledby='contained-modal-title-vcenter'
        >
          <div className='loader-container'>
            <ColorRing
              visible={true}
              height='160'
              width='160'
              ariaLabel='blocks-loading'
              wrapperStyle={{}}
              wrapperClass='blocks-wrapper'
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
          </div>
        </div>
      ) : null}
      <Helmet>
        <title> SignIn | {company_details.WebApp_Name}</title>
      </Helmet>
      <ToastContainer />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          background: 'white'
        }}
      >
        <a href='/ApplyJob' target='_blank' rel='noopener noreferrer'>
          <button type='button' className='btn btn-success m-1'>
            Apply Job
          </button>
        </a>
      </div>
      <div className='container-ess'>
        <div className='left-panel'>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className='logo'>
              {/* <span className="ess">Employee Self Service <span className="ESS"> (E.S.S)</span></span> */}
            </div>

            <div
              className='second-div'
              style={{
                width: '90%',
                maxHeight: '100%',
                boxShadow: ' rgba(149, 157, 165, 0.2) 0px 8px 24px',
                borderRadius: '10px'
              }}
            >
              <Row
                className='justify-content-center'
                style={{ width: '100%', borderRadius: '10px' }}
              >
                <Col lg={12} md={12} xl={12}>
                  <Card className='overflow-hidden'>
                    <div
                      className='tw-mb-2 tw-mt-2'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Col lg={6} md={6} sm={4} xs={6}>
                          <img src={profile} alt='' className='img-fluid' />
                        </Col>
                      </Row>
                    </div>

                    <CardBody className='pt-0'>
                      <div
                        className='tw-mb-4'
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          textAlign: 'center',
                          color: 'black',
                          fontSize: '14px',
                          fontWeight: 'bold'
                        }}
                      >
                        Login
                      </div>

                      {/* -------------------------input Feild-------------------------- */}
                      <div>
                        <Form
                          className='form-horizontal'
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {error ? (
                            <Alert
                              color='danger'
                              style={{ fontSize: '12px', padding: '6px' }}
                            >
                              Username and password are invalid. Please enter
                              correct username and password
                            </Alert>
                          ) : null}

                          <div
                            className='mb-2'
                            style={{
                              fontSize: '12px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              flexDirection: 'column'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                height: 'auto',
                                padding: '0',
                                margin: '0',
                                alignItems: 'flex-start'
                              }}
                            >
                              <span>Email</span>
                            </div>

                            <div>
                              <Input
                                name='username'
                                className='form-custom'
                                placeholder='Enter email'
                                type='text'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ''}
                                invalid={
                                  // validation.touched.username
                                  //  &&
                                  validation.errors.username ? true : false
                                }
                              />
                              {validation.touched.username &&
                              validation.errors.username ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div
                            className='mb-2'
                            style={{
                              fontSize: '12px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              flexDirection: 'column'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                height: 'auto',
                                padding: '0',
                                margin: '0',
                                alignItems: 'flex-start'
                              }}
                            >
                              <span>Password</span>
                            </div>

                            <div>
                              <InputGroup>
                                <Input
                                  name='password'
                                  className='form-custom'
                                  value={validation.values.password || ''}
                                  type={showPass ? 'text' : 'password'}
                                  placeholder='Enter Password'
                                  onChange={validation.handleChange}
                                  onBlur={e => {
                                    validation.handleBlur(e)
                                    setIsFocused(false)
                                  }}
                                  onFocus={() => setIsFocused(true)}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.values.password && (
                                  <InputGroupText
                                    style={{
                                      background: 'white',
                                      borderTop: 'white',
                                      borderRight: 'white',
                                      borderBottom:
                                        validation.touched.password &&
                                        validation.errors.password
                                          ? '2px solid #F46A6A'
                                          : isFocused
                                          ? '2px solid blue'
                                          : '2px solid black',

                                      transition: 'border-color 0.3s ease'
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        size: 16
                                      }}
                                    >
                                      <div
                                        onClick={() => {
                                          setshowPass(!showPass)
                                        }}
                                      >
                                        {showPass ? (
                                          <FaEyeSlash style={{ size: 16 }} />
                                        ) : (
                                          <FaEye size={16} />
                                        )}
                                      </div>
                                    </IconContext.Provider>
                                  </InputGroupText>
                                )}
                              </InputGroup>

                              <div
                                // style={{ fontSize: 10, color: "#F46A6A" }}

                                className='mt-1 error-text'
                                color='danger'
                              >
                                {validation.touched.password &&
                                validation.errors.password
                                  ? 'Please Enter Your Password'
                                  : null}
                              </div>

                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className='form-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id='customControlInline'
                              // onClick={()=>{
                              //    setremember_me(!remember_me)
                              //   }}
                              readOnly={true}
                              // checked={remember_me}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='customControlInline'
                              style={{ fontSize: '12px' }}
                            >
                              Remember me
                            </label>
                          </div>

                          <div className='mt-3 d-grid'>
                            <button
                              className='btn btn-primary btn-block'
                              type='submit'
                              disabled={
                                validation.values.username === '' ||
                                validation.values.password === ''
                              }
                            >
                              Log In
                            </button>
                          </div>

                          <div
                            className='mt-2 text-center'
                            style={{ fontSize: '12px' }}
                          >
                            <Link
                              to='/emailverification'
                              className='text-muted'
                            >
                              <i className='mdi mdi-lock me-1' />
                              Forgot your password?
                            </Link>
                          </div>

                          {/* <div
                        className="mt-2 text-center"
                        style={{ fontSize: "12px" }}
                      >
                        <Link to="/resetpassword" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Reset your password?
                        </Link>
                      </div> */}
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className='right-panel'>
          {/* <span className="right-txt">Welcome back! Please sign in to your <span className="right-span">Employee Self Service </span> account</span> */}
          <span className='right-txt'>
            <span className='right-span'>Employee Self Service (ESS)</span>
          </span>
          <p className='rig-text'>
            Let’s make work a little easier. From pay slips and attendance to
            leave management, holidays, and more – everything you need is right
            here, anytime you need it !
          </p>

          <div className='chart-placeholder'>
            {/* <p className="cell-txt">Sales Report</p>
            Placeholder for the chart */}
            {/* <div className="bar-chart"> */}
            <img
              src={Designer}
              style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                borderRadius: '8px'
              }}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default SignIn
