import React from 'react'
import { Navigate } from 'react-router-dom'
import Hierarchy from './screens/ems/Hierarchy/Hierarchy.js'
import PersonalDetails from './screens/ems/PersonalDetails/PersonalDetails.js'
import UserInviteForm from './screens/hr/invite-user/UserInviteForm.js'
import UserDetailsForm from './screens/hr/user-details/userDetails.js'
import OfferLetterTemplates from './screens/hr/OfferLetterTemplates/OfferLetterTemplates.js'

// import AddLeaveTracker from "./screens/ems/leave/leaveTracker/AddLeaveTracker.js";
// Authentication
const SignIn = React.lazy(() =>
  import('./screens/authentication/signin/SignIn.js')
)

const ResetPassword = React.lazy(() =>
  import('./screens/authentication/resetpassword/ResetPassword.js')
)
const EmailVerification = React.lazy(() =>
  import('./screens/authentication/signin/EmailVerification')
)
const Otp = React.lazy(() => import('./screens/authentication/signin/Otp'))

const PasswordAuthentication = React.lazy(() =>
  import('./screens/authentication/signin/PasswordAuthentication')
)

const AdminChangePassword = React.lazy(() =>
  import('./screens/ems/adminChangePasspword/AdminChangePassword.js')
)

const OnboardingForm = React.lazy(() =>
  import('./screens/ems/onboarding/onboarding_form')
)

const ApplyJob = React.lazy(() => import('./screens/ems/job/ApplyJob'))
const UpdateApplication = React.lazy(() =>
  import('./screens/ems/job/UpdateApplication')
)

//--------------- crm
const DragAndDropList = React.lazy(() =>
  import('./screens/crm/DragAndDropList.js')
)
const Deals = React.lazy(() => import('./screens/crm/deals/Deals.js'))

const ContectPerson = React.lazy(() =>
  import('./screens/crm/contectperson/ContectPerson.js')
)
const AddContectPerson = React.lazy(() =>
  import('./screens/crm/contectperson/AddContectPerson.js')
)

const Company = React.lazy(() => import('./screens/crm/company/Company.js'))
const AddCompany = React.lazy(() =>
  import('./screens/crm/company/AddCompany.js')
)

const AddDeals = React.lazy(() => import('./screens/crm/deals/AddDeals.js'))

const LeadDetails = React.lazy(() =>
  import('./screens/crm/leadDetails/LeadDetails.js')
)
const Ground = React.lazy(() => import('./screens/crm/DND/Ground.js'))

const CustomDND = React.lazy(() =>
  import('./screens/crm/customDND/CustomDND.js')
)
// dynamic permission

//Permission Modal
const AddPermission = React.lazy(() =>
  import('./screens/ems/permission/AddPermission.js')
)
const Permission = React.lazy(() =>
  import('./screens/ems/permission/Permission.js')
)

//Dashboard
const Dashboard = React.lazy(() => import('./screens/dashboard/Dashboard.js'))

const BranchDailyDetails = React.lazy(() =>
  import('./screens/dashboard/dashboardTypes/BranchDailyDetails.js')
)
const OrderDetails = React.lazy(() =>
  import('./screens/dashboard/dashboardTypes/OrderDetails.js')
)
// 404
const PageNotFound = React.lazy(() =>
  import('./screens/utilities/page404/Page404.js')
)
const Project = React.lazy(() => import('./screens/ems/projects/Project.js'))

const AddProject = React.lazy(() =>
  import('./screens/ems/projects/AddProject.js')
)
// for the vendor
const Vendor = React.lazy(() => import('./screens/master/vendor/Vendor.js'))
const UpateCompanyType = React.lazy(() =>
  import('./screens/master/vendor/UpadteCompanyType.js')
)

const AddVendor = React.lazy(() =>
  import('./screens/master/vendor/AddVendor.js')
)
const VendorHistoryPage = React.lazy(() =>
  import('./screens/master/vendor/vendorHistory/VendorHistoryPage.js')
)

// setting
const Setting = React.lazy(() => import('./screens/setting/Setting.js'))

const LoginDetails = React.lazy(() =>
  import('./screens/ems/loginDetails/LoginDetails.js')
)
const Users = React.lazy(() => import('./screens/ems/user/Users.js'))
const AddDailyTask = React.lazy(() =>
  import('./screens/ems/taskTracker/AddDailyTask.js')
)
const DailyTask = React.lazy(() =>
  import('./screens/ems/taskTracker/DailyTask.js')
)
const YesterdayTask = React.lazy(() =>
  import('./screens/ems/taskTracker/YesterdayTask.js')
)
const DailyTaskReport = React.lazy(() =>
  import('./screens/ems/taskTracker/DailyTaskReport.js')
)

const UserInfo = React.lazy(() => import('./screens/ems/user/UserInfo.js'))
const OfferLetter = React.lazy(() =>
  import('./screens/hr/OfferLetterTemplates/OfferLetter.js')
)

// team hierarchy
const TeamHierarchy = React.lazy(() =>
  import('./screens/ems/Hierarchy/TeamHierarchy')
)

const Department = React.lazy(() =>
  import('./screens/organisation/department/Department.js')
)
const AddDepartment = React.lazy(() =>
  import('./screens/organisation/department/AddDepartment.js')
)
const AddDesignation = React.lazy(() =>
  import('./screens/organisation/designation/AddDesignation.js')
)
const AddRole = React.lazy(() =>
  import('./screens/organisation/role/AddRole.js')
)
const Role = React.lazy(() => import('./screens/organisation/role/Role.js'))

const Team = React.lazy(() => import('./screens/ems/team/Team.js'))
const AddTeam = React.lazy(() => import('./screens/ems/team/AddTeam.js'))
const AddTeamAttendance = React.lazy(() =>
  import('./screens/myteam/AddTeamAttendance.js')
)
const TeamMembersLeave = React.lazy(() =>
  import('./screens/myteam/TeamMembersLeave.js')
)

const Designation = React.lazy(() =>
  import('./screens/organisation/designation/Designation.js')
)

const HrClaim = React.lazy(() => import('./screens/hr/claim/HrClaim.js'))
const Claims = React.lazy(() => import('./screens/ems/claim/Claims.js'))
const AddClaims = React.lazy(() => import('./screens/ems/claim/AddClaims.js'))
const UpdateClaim = React.lazy(() =>
  import('./screens/ems/claim/UpdateClaim.js')
)
const MyAttendance = React.lazy(() =>
  import('./screens/hr/attendance/myAttendance/MyAttendance.js')
)
const Hrattenance = React.lazy(() =>
  import('./screens/hr/hrattendance/Hrattenance.js')
)
const AddHrAttendance = React.lazy(() =>
  import('./screens/hr/hrattendance/AddHrAttendance.js')
)
const Hrleave = React.lazy(() => import('./screens/hr/hrleave/Hrleave.js'))
const HrPayslip = React.lazy(() =>
  import('./screens/hr/hrpayslip/HrPayslip.js')
)
const AddHrpayslip = React.lazy(() =>
  import('./screens/hr/hrpayslip/AddHrpayslip.js')
)
const MyAttendanceCalenderView = React.lazy(() =>
  import('./screens/hr/attendance/myAttendance/MyAttendanceCalenderView.js')
)
const AttendanceReport = React.lazy(() =>
  import('./screens/hr/attendance/empAttendance/AttendanceReport.js')
)
const TeamAttendanceReport = React.lazy(() =>
  import('./screens/myteam/TeamAttendanceReport.js')
)
const MyLeaveCalendarView = React.lazy(() =>
  import('./screens/ems/leave/leaveTracker/MyLeaveCalendarView.js')
)
const TodayAttendance = React.lazy(() =>
  import('./screens/hr/attendance/todayAttendance/TodayAttendance.js')
)
// user profile
const UserProfile = React.lazy(() =>
  import('./screens/ems/userProfile/UserProfileOld.js')
)

// My team Section --------------

const TeamLeave = React.lazy(() => import('./screens/myteam/TeamLeave.js'))
const TeamPerformance = React.lazy(() =>
  import('./screens/myteam/TeamPerformance.js')
)
const TeamAttendance = React.lazy(() =>
  import('./screens/myteam/TeamAttendance.js')
)
const MyTeam = React.lazy(() => import('./screens/myteam/MyTeam.js'))

// ---------------------------------

// ---profile section
const DemoProfile = React.lazy(() =>
  import('./screens/ems/userProfile/UserProfile.js')
)

// HR
const OnBoardEmployee = React.lazy(() =>
  import('./screens/hr/onBoardEmployee/OnBoardEmployee.js')
)

const EmpAttendance = React.lazy(() =>
  import('./screens/hr/attendance/empAttendance/Attendance.js')
)

const AddEmpAttendance = React.lazy(() =>
  import('./screens/hr/attendance/empAttendance/AddEmpAttendance.js')
)
const Employee = React.lazy(() => import('./screens/hr/employees/Employee.js'))
// employee

const UserStepForm = React.lazy(() =>
  import('./screens/ems/employee/UserStepForm.js')
)
const UserFormComplete = React.lazy(() =>
  import('./screens/ems/employee/UserFormComplete.js')
)

const EmployeeTab = React.lazy(() =>
  import('./screens/ems/employee/EmployeeTab.js')
)
const AddEmployeeAsset = React.lazy(() =>
  import('./screens/ems/employee/AddEmployeeAsset.js')
)
const Asset = React.lazy(() => import('./screens/ems/employee/Asset.js'))
const AddAsset = React.lazy(() => import('./screens/ems/employee/AddAsset.js'))
const UpdateAsset = React.lazy(() =>
  import('./screens/ems/employee/UpdateAsset.js')
)
// performance management

const AssignTask = React.lazy(() =>
  import('./screens/performance/assigntask/AssignTask.js')
)
const AddAssignTask = React.lazy(() =>
  import('./screens/performance/assigntask/AddAssignTask.js')
)
const Review = React.lazy(() =>
  import('./screens/performance/review/Review.js')
)
const AddReview = React.lazy(() =>
  import('./screens/performance/review/AddReview.js')
)
const Performance = React.lazy(() =>
  import('./screens/performance/performance/Performance.js')
)
const UserWiseView = React.lazy(() =>
  import('./screens/performance/performance/UserWiseView.js')
)
const UserAssignedTask = React.lazy(() =>
  import('./screens/ems/performance/UserAssignedTask.js')
)
const UserFeedback = React.lazy(() =>
  import('./screens/ems/performance/UserFeedback.js')
)
// ====================================end =================

// Leave
const AddLeaveCategory = React.lazy(() =>
  import('./screens/ems/leave/leaveCategory/AddLeaveCategory.js')
)
const AddLeave = React.lazy(() =>
  import('./screens/ems/leave/leaveApply/AddLeave.js')
)
const AddLeaveMain = React.lazy(() =>
  import('./screens/ems/leave/leaveApply/AddLeaveMain.js')
)
const UserLeave = React.lazy(() =>
  import('./screens/ems/leave/leaveApply/UserLeave')
)
const LeaveSummary = React.lazy(() =>
  import('./screens/hr/hrleave/LeaveSummary.js')
)
const ViewLeave = React.lazy(() =>
  import('./screens/ems/leave/leaveApply/ViewLeave.js')
)
const LeaveTracker = React.lazy(() =>
  import('./screens/ems/leave/leaveTracker/LeaveTracker.js')
)
// const AddLeaveTracker = React.lazy(() =>
//   import("./screens/ems/leave/leaveTracker/AddLeaveTracker.js"),
// );

const Holidays = React.lazy(() =>
  import('./screens/organisation/holidays/Holiday.js')
)
const AddHoliday = React.lazy(() =>
  import('./screens/organisation/holidays/AddHoliday.js')
)
const HolidayMain = React.lazy(() =>
  import('./screens/organisation/holidays/HolidayMain.js')
)

// Salary Parameter
const SalaryParameterList = React.lazy(() =>
  import('./screens/organisation/salaryParameter/SalaryParameterList.js')
)
const SalaryParameter = React.lazy(() =>
  import('./screens/organisation/salaryParameter/SalaryParameter.js')
)

const DetailedReport = React.lazy(() =>
  import('./screens/analytic/detailedReport/DetailedReport.js')
)

const DetailedReportList = React.lazy(() =>
  import('./screens/analytic/detailedReport/DetailedReportList.js')
)

const ViewReport = React.lazy(() =>
  import('./screens/analytic/detailedReport/ViewReport.js')
)

const BranchReport = React.lazy(() =>
  import('./screens/analytic/branchReport/BranchReport.js')
)

// recrutment
const JobList = React.lazy(() =>
  import('./screens/hr/recruitment/jobAdvertisements/JobList.js')
)
const JobAdvertisements = React.lazy(() =>
  import('./screens/hr/recruitment/jobAdvertisements/JobAdvertisements.js')
)
const JobApplications = React.lazy(() =>
  import('./screens/hr/recruitment/jobApplications/JobApplications.js')
)

const SalaryComponentList = React.lazy(() =>
  import('./screens/organisation/salaryParameter/SalaryComponentList.js')
)
const SalaryComponent = React.lazy(() =>
  import('./screens/organisation/salaryParameter/SalaryComponent.js')
)

// performance
const AddDepartmentKpis = React.lazy(() =>
  import('./screens/hr/performance/departmentKpis/AddDepartmentKpis.js')
)
const AddEmployeeKpis = React.lazy(() =>
  import('./screens/hr/performance/employeeKpis/AddEmployeeKpis.js')
)

// training
const AddTraining = React.lazy(() =>
  import('./screens/ems/traning/training/AddTraining.js')
)
const AddTrainingShedule = React.lazy(() =>
  import('./screens/ems/traning/trainingSchedule/AddTrainingShedule.js')
)

// payroll

const Csvs = React.lazy(() => import('./screens/ems/payroll/csv/Csv.js'))
const AddCsvs = React.lazy(() => import('./screens/ems/payroll/csv/AddCsv.js'))

const PayRoll = React.lazy(() =>
  import('./screens/ems/payroll/payrollRecord/PayRoll.js')
)
const AddPayroll = React.lazy(() =>
  import('./screens/ems/payroll/payrollRecord/AddPayroll.js')
)

const PayslipPdf = React.lazy(() =>
  import('./screens/pdf/paySlipPdf/PayslipPdf.js')
)
const PaySlip = React.lazy(() =>
  // import("./screens/ems/payroll/payrollSlip/PaySlip.js")
  import('./screens/ems/payroll/payrollSlip/PaySlip.js')
)
const AddPaySlip = React.lazy(() =>
  import('./screens/ems/payroll/payrollSlip/AddPaySlip.js')
)
const PaySlipReport = React.lazy(() =>
  import('./screens/ems/payroll/payrollSlip/PaySlipReport.js')
)
// Policy
const AddPolicy = React.lazy(() => import('./screens/policy/AddPolicy.js'))
const Policy = React.lazy(() => import('./screens/policy/Policy.js'))
const ViewPolicy = React.lazy(() => import('./screens/policy/ViewPolicy.js'))

//ems
const Branches = React.lazy(() =>
  import('./screens/master/branches/Branches.js')
)

const AddBranch = React.lazy(() =>
  import('./screens/master/branches/AddBranch.js')
)
//for history
const BranchHistoryPage = React.lazy(() =>
  import('./screens/master/branches/branchHistory/BranchHistoryPage.js')
)

const Locations = React.lazy(() =>
  import('./screens/master/locations/Locations.js')
)

const AddLocation = React.lazy(() =>
  import('./screens/master/locations/AddLocation.js')
)

//Organisation

const Organisation = React.lazy(() =>
  import('./screens/organisation/Organisation.js')
)

const AddOrganisation = React.lazy(() =>
  import('./screens/organisation/AddOrganisation.js')
)

//for time
const AddTimeline = React.lazy(() =>
  import('./screens/dashboard/timeLineSection/addTimeline/AddTimeline.js')
)

const InitiateOnboarding = React.lazy(() =>
  import('./screens/ems/onboarding/InitiateOnBoarding.js')
)
const NewDasboard = React.lazy(() =>
  import('./screens/dashboard/NewDasboard.js')
)
//---------------------------------------------------------- resignation
const ApplyResignation = React.lazy(() =>
  import('./screens/Resignation/ApplyResignation.js')
)
const AddRegination = React.lazy(() =>
  import('./screens/Resignation/AddRegination.js')
)
const ReginationApproval = React.lazy(() =>
  import('./screens/hr/rejectionApproval/ReginationApproval.js')
)
const HrReginationApproval = React.lazy(() =>
  import('./screens/hr/rejectionApproval/HrReginationApproval.js')
)
const TeamReginationApproval = React.lazy(() =>
  import('./screens/myteam/TeamReginationApproval.js')
)
const AddTeamReginationApproval = React.lazy(() =>
  import('./screens/myteam/AddTeamReginationApproval.js')
)
// ----------------Correction Request----------------------------------------------------
const Correction = React.lazy(() =>
  import('./screens/correction/Correction.js')
)
const AddCorrection = React.lazy(() =>
  import('./screens/correction/AddCorrection.js')
)
const Hrcorrection = React.lazy(() =>
  import('./screens/hrcorrection/Hrcorrection.js')
)
const AddHrcorrection = React.lazy(() =>
  import('./screens/hrcorrection/AddHrcorrection.js')
)
const AddTeamCorrection = React.lazy(() =>
  import('./screens/myteam/AddTeamCorrection.js')
)
const TeamCorrectionRequest = React.lazy(() =>
  import('./screens/myteam/TeamCorrectionRequest.js')
)
// --------------------------------------------------------------------

//Finance & Account
const AccountMaster = React.lazy(() =>
  import('./screens/finance/accountmaster/AccountMaster.js')
)
const AddAccountMaster = React.lazy(() =>
  import('./screens/finance/accountmaster/AddAccountMaster.js')
)

const BankAccountMaster = React.lazy(() =>
  import('./screens/finance/bankaccountmaster/BankAccountMaster.js')
)
const AddBankAccountMaster = React.lazy(() =>
  import('./screens/finance/bankaccountmaster/AddBankAccountMaster.js')
)

const AccountSubGroupMaster = React.lazy(() =>
  import('./screens/finance/accountsubgroupmaster/AccountSubGroupMaster.js')
)
const AddAccountSubGroupMaster = React.lazy(() =>
  import('./screens/finance/accountsubgroupmaster/AddAccountSubGroupMaster.js')
)

//Support
const RaiseIssue = React.lazy(() => import('./screens/support/RaiseIssue.js'))
const AddRaiseIssue = React.lazy(() =>
  import('./screens/support/AddRaiseIssue.js')
)
const IssueReport = React.lazy(() =>
  import('./screens/support/report/IssueReport.js')
)
const Demo = React.lazy(() => import('./screens/support/Status.js'))

const PendingRequest = React.lazy(() =>
  import('./screens/support/PendingRequest.js')
)
const CompletedRequest = React.lazy(() =>
  import('./screens/support/CompletedRequest.js')
)
const InProgressRequest = React.lazy(() =>
  import('./screens/support/InProgressRequest.js')
)
const TodaysRequest = React.lazy(() =>
  import('./screens/support/TodaysRequest.js')
)

// blog alll the routes
const Blog = React.lazy(() => import('./screens/blog/Blog'))
const AddBlog = React.lazy(() => import('./screens/blog/AddBlog'))
const RequestedDemo = React.lazy(() =>
  import('./screens/blog/demo/RequestedDemo.js')
)
const Subscriber = React.lazy(() =>
  import('./screens/blog/subscribe/Subscriber.js')
)
const GetInTouch = React.lazy(() =>
  import('./screens/blog/getintouch/GetInTouch.js')
)
// 
const ApprovedCorrectionRequestHr = React.lazy(() =>
  import('./screens/hrcorrection/ApprovedCorrectionRequestHr.js')
)
const RejectedCorrection = React.lazy(() =>
  import('./screens/hrcorrection/RejectedCorrection.js')
)

const TeamCorrectionApproval = React.lazy(() =>
  import('./screens/myteam/TeamCorrectionApproval.js')
)
const TeamCorrectionRejected = React.lazy(() =>
  import('./screens/myteam/TeamCorrectionRejected.js')
)

// Routes
const auth_routes = [
  { path: '/', element: <SignIn /> },
  { path: '/signin', element: <SignIn /> },
  // { path: "/dashboard", element: <Navigate to="/signin" /> },
  { path: '/resetpassword', element: <ResetPassword /> },

  {
    path: '/ApplyJob',
    name: 'ApplyJob',
    element: <ApplyJob />
  },

  {
    path: '/UpdateApplication',
    name: 'UpdateApplication',
    element: <UpdateApplication />
  },

  {
    path: '/emailverification',
    name: 'EmailVerification',
    element: <EmailVerification />
  },
  {
    path: '/otp',
    name: 'Otp',
    element: <Otp />
  },
  {
    path: '/passwordauthentication',
    name: 'PasswordAuthentication',
    element: <PasswordAuthentication />
  },
  {
    path: '/hr/UserDetails',
    element: <UserDetailsForm />
  }
]

const routes = [
  { path: '/signin', element: <Navigate to='/dashboard' /> },
  { path: '/', element: <Navigate to='/dashboard' /> },
  { path: '/404', element: <PageNotFound /> },
  { path: '*', element: <Navigate to='/404' replace /> },

  // -------------------Performance management

  {
    path: '/UserWiseView',
    element: <UserWiseView />
  },
  {
    path: '/ApprovedCorrectionRequestHr',
    element: <ApprovedCorrectionRequestHr />
  },
  {
    path: '/RejectedCorrection',
    element: <RejectedCorrection />
  },
  {
    path: '/TeamCorrectionRejected',
    element: <TeamCorrectionRejected />
  },
  {
    path: '/TeamCorrectionApproval',
    element: <TeamCorrectionApproval />
  },
  {
    path: '/UserFeedback',
    element: <UserFeedback />
  },

  //------------------- crm


  {
    path: '/crm/DragAndDropList',
    element: <DragAndDropList />
  },
 
  {
    path: '/crm/leadDetails/LeadDetails',
    element: <LeadDetails />
  },
  {
    path: '/crm/DND/Ground',
    element: <Ground />
  },


  //  testing

  { path: '/dashboard', element: <Dashboard /> },
  {
    path: '/dashboard/DashboardTypes/BranchDailyDetails',
    element: <BranchDailyDetails />
  },
  {
    path: '/dashboard/dashboardTypes/OrderDetails',
    element: <OrderDetails />
  },

  {
    path: '/ems/users/Userinfo',
    element: <UserInfo />
  },

  // for the vendor

  //for timeline
  {
    path: '/dashboard/timeLineSection/addTimeline/AddTimeline',
    element: <AddTimeline />
  },

  //=========department
  {
    path: '/screens/organisation/department/AddDepartment.js',
    element: <AddDepartment />,
    page_type: 'ADD',
    model: 'ORGANIZATION',
    sub_model: 'DEPARTMENT'
  },

  {
    path: '/screens/organisation/department/Department.js',
    element: <Department />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'DEPARTMENT'
  },

  // ============Designation==================

  {
    path: '/organisation/designation/Designation',
    element: <Designation />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'DESIGNATION'
  },

  {
    path: '/organisation/designation/AddDesignation',
    element: <AddDesignation />,
    page_type: 'ADD',
    model: 'ORGANIZATION',
    sub_model: 'DESIGNATION'
  },
  // =====================role===============
  {
    path: '/role/Role',
    element: <Role />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'ROLE'
  },

  {
    path: '/role/AddRole',
    element: <AddRole />,
    page_type: 'ADD',
    model: 'ORGANIZATION',
    sub_model: 'ROLE'
  },

  // -----------------Holiday=================

  {
    path: '/organisation/holidays/HolidayMain',
    element: <HolidayMain />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'HOLIDAYS'
  },

  {
    path: '/organisation/holidays/AddHoliday',
    element: <AddHoliday />,
    page_type: 'ADD',
    model: 'ORGANIZATION',
    sub_model: 'HOLIDAYS'
  },
  //holiday calender
  {
    path: '/organisation/holidays/Holiday',
    element: <Holidays />
  },

  // ----------salary Component----------------

  {
    path: '/screens/organisation/salaryParameter/SalaryComponentList.js',
    element: <SalaryComponentList />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'SALARY COMPONENT'
  },

  {
    path: '/screens/organisation/salaryParameter/SalaryComponent.js',
    element: <SalaryComponent />,
    page_type: 'ADD',
    model: 'ORGANIZATION',
    sub_model: 'SALARY COMPONENT'
  },

  // ============PERMISSION=================
  {
    path: '/ems/permission',
    element: <Permission />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'PERMISSION'
  },
  {
    path: '/ems/permission/addpermission',
    element: <AddPermission />,
    page_type: 'ADD',
    model: 'ORGANIZATION',
    sub_model: 'PERMISSION'
  },

  // ===========PERMISSION ============
  {
    path: '/ems/project',
    element: <Project />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'PROJECT'
  },
  {
    path: '/ems/project/addproject',
    element: <AddProject />,
    page_type: 'ADD',
    model: 'ORGANIZATION',
    sub_model: 'PROJECT'
  },

  // ------------Hierarchy---------------

  {
    path: '/organisation/hierarchy/Hierarchy',
    element: <Hierarchy />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'HIERARCHY'
  },
  // ==============================ORGANIZATION END ============================
  // --------------- HR ---------------------

  {
    path: '/hr/employees/Employee',
    element: <Employee />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'EMPLOYEES'
  },
  {
    path: '/hr/InviteUser',
    element: <UserInviteForm />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'INVITE USER'
  },

  {
    path: '/hrattendance/Hrattenance',
    element: <Hrattenance />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'ATTENDANCE'
  },
  {
    path: '/hrattendance/AddHrAttendance',
    element: <AddHrAttendance />,
    page_type: 'ADD',
    model: 'HR',
    sub_model: 'ATTENDANCE'
  },
  {
    path: '/hrleave/Hrleave',
    element: <Hrleave />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'LEAVE MANAGEMENT'
  },
  {
    path: '/hrpayslip/HrPayslip',
    element: <HrPayslip />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'PAY SLIP'
  },
  {
    path: '/hrpayslip/AddHrpayslip',
    element: <AddHrpayslip />,
    page_type: 'ADD',
    model: 'HR',
    sub_model: 'PAY SLIP'
  },
  {
    path: '/OfferLetterTemplates/OfferLetter',
    element: <OfferLetter />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'OFFER LETTER TEMPLATES'
  },

  {
    path: '/hr/offer_letter_templates',
    element: <OfferLetterTemplates />,
    page_type: 'ADD',
    model: 'HR',
    sub_model: 'OFFER LETTER TEMPLATES'
  },
  {
    path: '/team/Team',
    element: <Team />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'TEAM'
  },
  {
    path: '/team/AddTeam',
    element: <AddTeam />,
    page_type: 'ADD',
    model: 'HR',
    sub_model: 'TEAM'
  },
  {
    path: '/ems/adminChangePassword',
    element: <AdminChangePassword />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'ADMIN CHANGE PASSWORD'
  },
  {
    path: '/Hrcorrection',
    element: <Hrcorrection />,
    page_type: 'VIEW',
    model: 'HR',
    sub_model: 'CORRECTION REQUEST'
  },

  //--------------------------------- HR END ----------EMS Start------------------

  {
    path: '/ems/logindetails/logindetails',
    element: <LoginDetails />,
    page_type: 'VIEW',
    model: 'EMS',
    sub_model: 'LOGIN DETAILS'
  },
  {
    path: '/ems/users',
    element: <Users />,
    page_type: 'VIEW',
    model: 'EMS',
    sub_model: 'USERS'
  },
  {
    path: '/ems/onboarding/initiate_onboarding',
    element: <InitiateOnboarding />,
    page_type: 'ADD',
    model: 'EMS',
    sub_model: 'USERS'
  },
  {
    path: '/hr/EmpAttendance',
    element: <EmpAttendance />,
    page_type: 'VIEW',
    model: 'EMS',
    sub_model: 'ATTENDANCE'
  },
  {
    path: '/hr/EmpAttendance/AddEmpAttendance',
    element: <AddEmpAttendance />,
    page_type: 'ADD',
    model: 'EMS',
    sub_model: 'ATTENDANCE'
  },
  {
    path: '/ems/leave',
    element: <AddLeaveMain />,
    page_type: 'VIEW',
    model: 'EMS',
    sub_model: 'LEAVE MANAGEMENT'
  },

  {
    path: '/ems/leave/AddLeave',
    element: <AddLeave />,
    page_type: 'ADD',
    model: 'EMS',
    sub_model: 'LEAVE MANAGEMENT'
  },
  {
    path: '/ems/payroll/payrollSlip/PaySlip',
    element: <PaySlip />,
    page_type: 'VIEW',
    model: 'EMS',
    sub_model: 'PAY SLIP'
  },
  {
    path: '/ems/payroll/payrollSlip/AddPaySlip',
    element: <AddPaySlip />,
    page_type: 'ADD',
    model: 'EMS',
    sub_model: 'PAY SLIP'
  },
  {
    path: '/ems/DailyTask',
    element: <DailyTask />,
    page_type: 'VIEW',
    model: 'EMS',
    sub_model: 'TASK TRACKER'
  },
  {
    path: '/ems/DailyTask/AddDailyTask',
    element: <AddDailyTask />,
    page_type: 'ADD',
    model: 'EMS',
    sub_model: 'TASK TRACKER'
  },
  {
    path: '/ems/YesterdayTask',
    element: <YesterdayTask />
  },
  {
    path: '/ems/DailyTaskReport',
    element: <DailyTaskReport />
  },
  {
    path: '/UserAssignedTask',
    element: <UserAssignedTask />,
    page_type: 'VIEW',
    model: 'EMS',
    sub_model: 'ASSIGN WORK'
  },
  {
    path: '/ems/claims',
    element: <Claims />,
    page_type: 'VIEW',
    model: 'EMS',
    sub_model: 'CLAIMS'
  },

  {
    path: '/ems/addclaims',
    element: <AddClaims />,
    page_type: 'ADD',
    model: 'EMS',
    sub_model: 'CLAIMS'
  },

  // ================EMS END ==============
  // ----------------- master Start----------
  {
    path: '/master/branches',
    element: <Branches />,
    page_type: 'VIEW',
    model: 'MASTER',
    sub_model: 'BRANCHES'
  },
  {
    path: '/master/branches/addbranch',
    element: <AddBranch />,
    page_type: 'ADD',
    model: 'MASTER',
    sub_model: 'BRANCHES'
  },
  {
    path: '/master/branches/BranchHistoryPage',
    element: <BranchHistoryPage />,
    page_type: 'VIEW',
    model: 'MASTER',
    sub_model: 'BRANCHES'
  },

  {
    path: '/master/locations',
    element: <Locations />,
    page_type: 'VIEW',
    model: 'MASTER',
    sub_model: 'LOCATIONS'
  },
  {
    path: '/master/locations/addlocation',
    element: <AddLocation />,
    page_type: 'ADD',
    model: 'MASTER',
    sub_model: 'LOCATIONS'
  },

  {
    path: '/master/vendor/Vendor',
    element: <Vendor />,
    page_type: 'VIEW',
    model: 'MASTER',
    sub_model: 'VENDOR'
  },
  {
    path: '/master/vendor/updatecompanytype',
    element: <UpateCompanyType />,
    page_type: 'ADD',
    model: 'MASTER',
    sub_model: 'VENDOR'
  },
  {
    path: '/master/vendor/AddVendor',
    element: <AddVendor />,
    page_type: 'ADD',
    model: 'MASTER',
    sub_model: 'VENDOR'
  },
  {
    path: '/master/vendor/vendorHistory/VendorHistoryPage',
    element: <VendorHistoryPage />,
    page_type: 'VIEW',
    model: 'MASTER',
    sub_model: 'VENDOR'
  },

  // -------------Master End ----------

  // ----------------- Support Start--------------

  {
    path: '/support/RaiseIssue',
    element: <RaiseIssue />,
    page_type: 'VIEW',
    model: 'SUPPORT',
    sub_model: 'RAISE ISSUE'
  },
  {
    path: '/support/AddRaiseIssue',
    element: <AddRaiseIssue />,
    page_type: 'ADD',
    model: 'SUPPORT',
    sub_model: 'RAISE ISSUE'
  },
  {
    path: '/support/CompletedRequest',
    element: <CompletedRequest />
  },
  {
    path: '/support/InProgressRequest',
    element: <InProgressRequest />
  },
  {
    path: '/support/TodaysRequest',
    element: <TodaysRequest />
  },
  {
    path: '/support/PendingRequest',
    element: <PendingRequest />
  },

  {
    path: '/support/report/IssueReport',
    element: <IssueReport />,
    page_type: 'VIEW',
    model: 'SUPPORT',
    sub_model: 'REPORT'
  },
  // -------Support End Here-------------
  // ----------My Team ============
  {
    path: '/myteam/MyTeam',
    element: <MyTeam />,
    page_type: 'VIEW',
    model: 'MY TEAM',
    sub_model: 'TEAM'
  },
  {
    path: '/myteam/TeamLeave',
    element: <TeamLeave />,
    page_type: 'VIEW',
    model: 'MY TEAM',
    sub_model: 'LEAVE MANAGEMENT'
  },
  {
    path: '/myteam/TeamAttendance',
    element: <TeamAttendance />,
    page_type: 'VIEW',
    model: 'MY TEAM',
    sub_model: 'ATTENDANCE'
  },
  {
    path: '/AddTeamAttendance',
    element: <AddTeamAttendance />,
    page_type: 'ADD',
    model: 'MY TEAM',
    sub_model: 'ATTENDANCE'
  },
  {
    path: '/Performance',
    element: <Performance />,
    page_type: 'VIEW',
    model: 'MY TEAM',
    sub_model: 'PERFORMANCE'
  },
  {
    path: '/AssignTask',
    element: <AssignTask />,
    page_type: 'VIEW',
    model: 'MY TEAM',
    sub_model: 'ASSIGN WORK'
  },
  {
    path: '/AddAssignTask',
    element: <AddAssignTask />,
    page_type: 'ADD',
    model: 'MY TEAM',
    sub_model: 'ASSIGN WORK'
  },
  {
    path: '/Review',
    element: <Review />,
    page_type: 'VIEW',
    model: 'MY TEAM',
    sub_model: 'FEEDBACK'
  },
  {
    path: '/AddReview',
    element: <AddReview />,
    page_type: 'ADD',
    model: 'MY TEAM',
    sub_model: 'FEEDBACK'
  },
  {
    path: '/TeamCorrectionRequest',
    element: <TeamCorrectionRequest />,
    page_type: 'VIEW',
    model: 'MY TEAM',
    sub_model: 'CORRECTION REQUEST'
  },
  //=============== END HERE =================
  // ---------- blog -----------

  {
    path: '/blog',
    element: <Blog />,
    page_type: 'VIEW',
    model: 'BLOG',
    sub_model: 'BLOG'
  },
  {
    path: '/AddBlog',
    element: <AddBlog />,
    page_type: 'ADD',
    model: 'BLOG',
    sub_model: 'BLOG'
  },
  {
    path: '/RequestedDemo',
    element: <RequestedDemo />,
    page_type: 'VIEW',
    model: 'BLOG',
    sub_model: 'REQUESTED DEMO'
  },
  {
    path: '/Subscriber',
    element: <Subscriber />,
    page_type: 'VIEW',
    model: 'BLOG',
    sub_model: 'SUBSCRIBER'
  },
  {
    path: '/GetInTouch',
    element: <GetInTouch />,
    page_type: 'VIEW',
    model: 'BLOG',
    sub_model: 'GET IN TOUCH'
  },

  //================== End =============
  // --------policy ===============

  {
    path: '/Policy',
    element: <Policy />,
    page_type: 'VIEW',
    model: 'POLICY',
    sub_model: 'POLICY'
  },
  {
    path: '/AddPolicy',
    element: <AddPolicy />,
    page_type: 'ADD',
    model: 'POLICY',
    sub_model: 'POLICY'
  },
  {
    path: '/ViewPolicy',
    element: <ViewPolicy />,
    page_type: 'VIEW',
    model: 'POLICY',
    sub_model: 'VIEW POLICY'
  },
// ----------Policy end ------
//-------- CRM --------------------

{
  path: '/ContectPerson',
  element: <ContectPerson />,
  page_type: 'VIEW',
  model: 'CRM',
  sub_model: 'CONTACT PERSON',
},
{
  path: '/AddContectPerson',
  element: <AddContectPerson />,
  page_type: 'ADD',
  model: 'CRM',
  sub_model: 'CONTACT PERSON',
},

{
  path: '/Company',
  element: <Company />,
  page_type: 'VIEW',
  model: 'CRM',
  sub_model: 'COMPANY',
},
{
  path: '/AddCompany',
  element: <AddCompany />,
  page_type: 'ADD',
  model: 'CRM',
  sub_model: 'COMPANY',
},
{
  path: '/crm/deals/Deals',
  element: <Deals />,
  page_type: 'VIEW',
  model: 'CRM',
  sub_model: 'COMPANY',
},
{
  path: '/crm/deals/AddDeals',
  element: <AddDeals />,
  page_type: 'ADD',
  model: 'CRM',
  sub_model: 'COMPANY',
},
{
  path: '/crm/customDND/CustomDND',
  element: <CustomDND />,
  page_type: 'VIEW',
  model: 'CRM',
  sub_model: 'CUSTOMDND',
},
// ================ END HERE =====================
  // ----------------------Resignation start---------------------
  {
    path: '/Resignation/ApplyResignation',
    element: <ApplyResignation />,
    page_type: 'VIEW',
    model: 'RESIGNATION',
    sub_model: 'RESIGNATION',
  },
  {
    path: '/Resignation/AddRegination',
    element: <AddRegination />,
    page_type: 'ADD',
    model: 'RESIGNATION',
    sub_model: 'RESIGNATION',
  },

//------------------ end here ------------------------- 

//================== Correction Start===================

{
  path: '/correction/Correction',
  element: <Correction />,
  page_type: 'VIEW',
  model: 'CORRECTION REQUEST',
  sub_model: 'CORRECTION REQUEST',
},
{
  path: '/correction/AddCorrection',
  element: <AddCorrection />,
  page_type: 'ADD',
  model: 'CORRECTION REQUEST',
  sub_model: 'CORRECTION REQUEST',
},

  // --------------------------
  {
    path: '/Analytics/DetailedReport',
    element: <DetailedReport />
  },
  {
    path: '/Analytics/DetailedReport/DetailedReportList',
    element: <DetailedReportList />
  },
  {
    path: '/Analytics/DetailedReport/ViewReport',
    element: <ViewReport />
  },
  { path: '/Analytics/BranchReport', element: <BranchReport /> },

  {
    path: '/TeamMembersLeave',
    element: <TeamMembersLeave />
  },
  //designation
  {
    path: '/organisation/designation/AddDesignation',
    element: <AddDesignation />
  },
  {
    path: '/organisation/designation/AddDesignation',
    element: <AddDesignation />
  },

  {
    path: '/organisation/personaldetails/PersonalDetails',
    element: <PersonalDetails />
  },
  {
    path: '/organisation/hierarchy/Hierarchy',
    element: <Hierarchy />
  },
  {
    path: '/organisation/personaldetails/PersonalDetails',
    element: <PersonalDetails />
  },
  // Attendance

  {
    path: '/attendance/myAttendance/MyAttendance',
    element: <MyAttendance />
  },

  {
    path: '/HrClaim',
    element: <HrClaim />
  },

  {
    path: '/ems/UpdateClaim',
    element: <UpdateClaim />
  },
  {
    path: '/ems/attendance/myAttendance/MyAttendanceCalenderView',
    element: <MyAttendanceCalenderView />
  },

  {
    path: '/Attendance/AttendanceReport',
    element: <AttendanceReport />
  },
  {
    path: '/Attendance/TeamAttendanceReport',
    element: <TeamAttendanceReport />
  },

  {
    path: '/ems/leave/leaveTracker/MyLeaveCalendarView',
    element: <MyLeaveCalendarView />
  },
  {
    path: '/attendance/todayAttendance/TodayAttendance',
    element: <TodayAttendance />
  },

  //user Profile
  {
    path: '/ems/userProfile/UserProfile',
    element: <UserProfile />
  },

  {
    path: '/NewDasboard',
    element: <NewDasboard />
  },

  {
    path: '/ReginationApproval',
    element: <ReginationApproval />
  },
  {
    path: '/HrReginationApproval',
    element: <HrReginationApproval />
  },
  {
    path: '/TeamReginationApproval',
    element: <TeamReginationApproval />
  },
  {
    path: '/AddTeamReginationApproval',
    element: <AddTeamReginationApproval />
  },
  // -------------Correction Request--------

  {
    path: '/AddTeamCorrection',
    element: <AddTeamCorrection />
  },

  {
    path: '/AddHrcorrection',
    element: <AddHrcorrection />
  },

  // -----------------------

  {
    path: '/myteam/TeamPerformance',
    element: <TeamPerformance />
  },

  //---- Profile Start
  {
    path: '/userProfile/Profile',
    element: <DemoProfile />
  },

  //--- profile end here
  // HR
  {
    path: '/hr/onBoardEmployee/OnBoardEmployee',
    element: <OnBoardEmployee />
  },

  //employee
  {
    path: '/ems/employee/UserStepForm',
    element: <UserStepForm />
  },
  {
    path: '/ems/employee/UserFormComplete',
    element: <UserFormComplete />
  },
  {
    path: '/ems/employee/EmployeeTab',
    element: <EmployeeTab />
  },
  {
    path: '/ems/employee/AddEmployeeAsset',
    element: <AddEmployeeAsset />
  },

  // Leave

  {
    path: '/UserLeave',
    element: <UserLeave />
  },
  {
    path: '/LeaveSummary',
    element: <LeaveSummary />
  },

  {
    path: '/ems/leave/ViewLeave',
    element: <ViewLeave />
  },
  {
    path: '/ems/leave/leaveCategory/AddLeaveCategory',
    element: <AddLeaveCategory />
  },
  {
    path: '/ems/addasset',
    element: <AddAsset />
  },
  {
    path: '/ems/UpdateAsset',
    element: <UpdateAsset />
  },
  {
    path: '/ems/asset',
    element: <Asset />
  },
  {
    path: '/ems/leave/leaveTracker/LeaveTracker.js',
    element: <LeaveTracker />
  },
  // {
  //   path: "/ems/leave/leaveTracker/AddLeaveTracker.js",
  //   element: <AddLeaveTracker/>,
  // },

  // Salary Parameter
  {
    path: '/screens/organisation/salaryParameter/SalaryParameterList.js',
    element: <SalaryParameterList />
  },
  {
    path: '/screens/organisation/salaryParameter/SalaryParameter.js',
    element: <SalaryParameter />
  },

  // recruitment

  {
    path: '/hr/recruitment/jobAdvertisements/JobList',
    element: <JobList />
  },

  {
    path: '/hr/recruitment/jobAdvertisements/JobAdvertisements',
    element: <JobAdvertisements />
  },
  {
    path: '/hr/recruitment/jobApplications/JobApplications',
    element: <JobApplications />
  },

  {
    path: '/hr/performance/departmentKpis/AddDepartmentKpis',
    element: <AddDepartmentKpis />
  },
  {
    path: '/hr/performance/employeeKpis/AddEmployeeKpis',
    element: <AddEmployeeKpis />
  },

  // for Team Hierarchy
  {
    path: '/TeamHierarchy',
    element: <TeamHierarchy />
  },

  //ems

  {
    path: '/ems/traning/training/AddTraining',
    element: <AddTraining />
  },
  {
    path: '/ems/traning/trainingSchedule/AddTrainingShedule',
    element: <AddTrainingShedule />
  },
  {
    path: '/ems/payroll/csvs/Csvs',
    element: <Csvs />
  },
  {
    path: '/ems/payroll/csvs/AddCsvs',
    element: <AddCsvs />
  },
  {
    path: '/ems/payroll/payrollRecords/PayRoll',
    element: <PayRoll />
  },
  {
    path: '/ems/payroll/payrollRecords/AddPayroll',
    element: <AddPayroll />
  },

  {
    path: '/pdf/PayslipPdf',
    element: <PayslipPdf />
  },

  {
    path: '/ems/PaySlipReport',
    element: <PaySlipReport />
  },
  //ems



  //Finance & Account
  {
    path: '/finance/accountmaster',
    element: <AccountMaster />
  },
  {
    path: '/finance/accountmaster/addaccountmaster',
    element: <AddAccountMaster />
  },
  {
    path: '/master/bankaccountmaster',
    element: <BankAccountMaster />
  },
  {
    path: '/master/bankaccountmaster/addbankaccountmaster',
    element: <AddBankAccountMaster />
  },
  {
    path: '/master/accountsubgroupmaster',
    element: <AccountSubGroupMaster />
  },
  {
    path: '/master/accountsubgroupmaster/addaccountsubgroupmaster',
    element: <AddAccountSubGroupMaster />
  },

  //Organisation
  {
    path: '/screens/organisation',
    element: <Organisation />,
    page_type: 'VIEW',
    model: 'ORGANIZATION',
    sub_model: 'ORGANIZATION'
  },
  {
    path: '/screens/organisation/addorganisation',
    element: <AddOrganisation />,
    page_type: 'ADD',
    model: 'ORGANIZATION',
    sub_model: 'ORGANIZATION'
  },
  {
    path: '/ems/onboarding/onboarding_form',
    element: <OnboardingForm />
  },
  {
    path: '/screens/setting/Setting',
    element: <Setting />
  },

  {
    path: '/support/Status',
    element: <Demo />
  }
]

export { auth_routes, routes }
