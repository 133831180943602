import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
  Form,
  Input,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Row,
  FormFeedback
} from 'reactstrap'
import * as Yup from 'yup'
import { ServerAddress } from '../../../constants/ServerAddress'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { IconContext } from 'react-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import {
  setAlertType,
  setDataExist,
  setShowAlert
} from '../../../store/alert/Alert'
import { useDispatch } from 'react-redux'
import NSearchInput from '../../../components/formComponent/nsearchInput/NSearchInput'
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md'
import { MdAdd, MdDeleteForever } from 'react-icons/md'
import MultiRowSearchInput from '../../../components/formComponent/multiRowSearchInput/MultiRowSearchInput'
import toTitleCase from '../../../lib/titleCase/TitleCase'
import SearchInput from '../../../components/formComponent/searchInput/SearchInput'
import { FiCheckSquare, FiSquare } from 'react-icons/fi'
import PageTitle from '../../../components/pageTitle/PageTitle'
import Title from '../../../components/title/Title'
import { ColorRing } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TransferList from '../../../components/formComponent/transferList/TransferList'

const UserInfo = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  //redux state
  const accessToken = useSelector(state => state.authentication.access_token)
  const user_detail = useSelector(state => state.authentication.userdetails)
  const user_id = useSelector(state => state.authentication.userdetails.id)

  const [show_loader, setshow_loader] = useState(false)

  // Error State
  const [joined_date, setjoined_date] = useState('')
  const [joined_date_err, setjoined_date_err] = useState(false)

  const [title_list] = useState(['Mr.', 'Mrs.', 'Ms.'])
  const [title_is, settitle_is] = useState('')
  const [title_error, settitle_error] = useState(false)

  const [is_creadentials, setis_creadentials] = useState(false)

  const [aadhar_number, setaadhar_number] = useState('')
  const [aadhar_number_err, setaadhar_number_err] = useState(false)
  const [duplicate_aadhar_err, setduplicate_aadhar_err] = useState(false)
  const [aadhar_err, setaadhar_err] = useState(false)

  const [usernamee, setusernamee] = useState('')
  const [username_err, setusername_err] = useState(false)
  const [dup_username_err, setdup_username_err] = useState(false)

  const [email, setemail] = useState('')
  const [email_err, setemail_err] = useState(false)
  const [dup_email_err, setdup_email_err] = useState(false)

  const [pf_no, setpf_no] = useState('')
  const [pf_no_err, setpf_no_err] = useState(false)

  const [uan_no, setuan_no] = useState('')
  const [uan_no_err, setuan_no_err] = useState(false)

  const [esic_no, setesic_no] = useState('')
  const [esic_no_err, setesic_no_err] = useState(false)

  // Organization
  const [org, setorg] = useState('')
  const [org_id, setorg_id] = useState('')
  const [org_error, setorg_error] = useState(false)
  const [org_list, setorg_list] = useState([])
  const [org_page, setorg_page] = useState(1)
  const [org_search_item, setorg_search_item] = useState('')
  const [org_loaded, setorg_loaded] = useState(false)
  const [org_count, setorg_count] = useState(1)
  const [org_bottom, setorg_bottom] = useState(103)

  // Home Branch
  const [home_branch, sethome_branch] = useState('')
  const [home_branch_id, sethome_branch_id] = useState('')
  const [search_branch, setsearch_branch] = useState('')
  const [page, setpage] = useState(1)
  const [home_branch_list, sethome_branch_list] = useState([])
  const [home_branch_err, sethome_branch_err] = useState('')
  const [branch_count, setbranch_count] = useState(1)
  const [branch_loaded, setbranch_loaded] = useState(false)
  const [branch_bottom, setbranch_bottom] = useState(103)

  // employee Type
  const [employee_code, setemployee_code] = useState('')
  const [employee_type, setemployee_type] = useState('')
  const [employee_type_id, setemployee_type_id] = useState('')
  const [employee_type_list, setemployee_type_list] = useState([
    ['PART', 'Part Time'],
    ['FULL', 'Full Time'],
    ['TRAINEE', 'Trainee'],
    ['INTERN', 'Internship'],
    ['OTHER', 'Other']
  ])
  const [employee_type_error, setemployee_type_error] = useState(false)

  const [emp_type_err, setemp_type_err] = useState(false)
  const [employment_type, setemployment_type] = useState('')
  const [employment_type_list] = useState([
    'Full Time',
    'Part Time',
    'Intership ',
    'Other'
  ])

  // user state
  const [User, setUser] = useState('')
  const [User_id, setuser_id] = useState(0)
  const [User_list, setUser_list] = useState([])
  const [user_err, setuser_err] = useState(false)
  const [user_count, setuser_count] = useState(1)
  const [user_bottom, setuser_bottom] = useState(103)
  const [user_loaded, setuser_loaded] = useState(false)
  const [user_page, setuser_page] = useState(1)
  const [user_search, setuser_search] = useState('')
  const [teamlead_loaded, setteamlead_loaded] = useState(false)

  // department state ====================
  const [user_department_list, setuser_department_list] = useState([])
  const [user_department, setuser_department] = useState('')
  const [user_department_id, setuser_department_id] = useState('')
  const [departmentloaded, setdepartmentloaded] = useState('')

  const [user_department_list2, setuser_department_list2] = useState([])
  const [up_department_list, setup_department_list] = useState([])
  // const [user_department, setuser_department] = useState("");
  // const [user_department_id, setuser_department_id] = useState("");

  const [user, setuser] = useState('')
  const [user_department_page, setuser_department_page] = useState(1)
  const [user_department_search, setuser_department_search] = useState('')
  const [user_department_loaded, setuser_department_loaded] = useState(false)
  const [user_department_count, setuser_department_count] = useState(1)
  const [user_department_bottom, setuser_department_bottom] = useState(56)
  const [user_department_err, setuser_department_err] = useState(false)

  useEffect(() => {
    let arr = user_department_list2.map(subArray => subArray[0])
    setuser_department_id(arr)
  }, [user_department_list2])

  const [designationNameList, setDesignationNameList] = useState('')
  const [designation, setdesignation] = useState('')
  const [designation_id, setdesignation_id] = useState('')
  const [designation_err, setdesignation_err] = useState('')
  const [designation_page, setdesignation_page] = useState(1)
  const [designation_search, setdesignation_search] = useState('')
  const [designation_count, setdesignation_count] = useState(1)
  const [designation_loaded, setdesignation_loaded] = useState(false)
  const [designation_bottom, setdesignation_bottom] = useState(103)

  const [designationLoaded, setdesignationLoaded] = useState('')

  // role name ---------------
  const [roleNameList, setroleNameList] = useState('')
  const [search_role, setsearch_role] = useState('')
  const [role, setrole] = useState('')
  const [page_role, setpage_role] = useState(1)
  const [role_id, setrole_id] = useState('')
  const [role_err, setrole_err] = useState('')
  const [role_count, set_role_count] = useState(1)
  const [role_loaded, set_role_loaded] = useState(false)
  const [role_bottom, setrole_bottom] = useState(103)

  const [cm_list, setcm_list] = useState(['Checker', 'Maker'])
  const [cm, setcm] = useState('')
  const [cm_error, setcm_error] = useState(false)

  // for update state
  const [isupdating, setisupdating] = useState(false)
  const [invite_data, setinvite_data] = useState('')
  const [invite_data_id, setinvite_data_id] = useState('')

  const [refresh, setrefresh] = useState(false)
  const [salary_type, setsalary_type] = useState(['', ''])
  const [amount, setamount] = useState('')

  const [salary_component_list, setsalary_component_list] = useState([])
  // const [salary_component, setsalary_component] = useState("");
  // const [salary_component_id, setsalary_component_id] = useState("");
  const [salary_component_page, setsalary_component_page] = useState(1)
  const [salary_component_search, setsalary_component_search] = useState('')
  const [salary_component_loaded, setsalary_component_loaded] = useState(false)
  const [salary_component_count, setsalary_component_count] = useState(1)
  const [salary_component_bottom, setsalary_component_bottom] = useState(103)
  // const [salary_component_err, setsalary_component_err] = useState(false);
  // const [salary_component_text, setsalary_component_text] = useState("");

  const [salary_info_error, setsalary_info_error] = useState(false)
  const [salary_info_text, setsalary_info_text] = useState('')
  const [update_salary_info, setupdate_salary_info] = useState([])

  const [circle_btn3, setcircle_btn3] = useState(true)
  const toggle_circle3 = () => {
    setcircle_btn3(!circle_btn3)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      // username: invite_data.username || "",
      // email: invite_data.email || "",
      phone_number: invite_data.mobilenumber || '',
      // designation: toTitleCase(invite_data.designation) || "",
      // aadhar_number: invite_data.aadhar_number || "",
      first_name: toTitleCase(invite_data.firstname) || '',
      last_name: toTitleCase(invite_data.lastname) || '',
      other_employement: toTitleCase(invite_data.other_employement) || '',
      middle_name: toTitleCase(invite_data.middlename) || '',
      hr_email: invite_data.hr_email || ''
      // uan_no: "",
      // pf_no: "",
      // esic_no: "",
    },
    validationSchema: Yup.object({
      // aadhar_number: Yup.string()
      //   .min(12, "Invalid Aadhar Number")
      //   .max(12, "Please Enter 12 Digit")
      //   .required("Please Enter Aadhar Number"),
      first_name: Yup.string().min(2).max(50).required('Enter First Name'),
      // middle_name: Yup.string()
      //   .min(2)
      //   .max(20)
      //   .required("Please Enter Middle Name"),

      other_employment: Yup.string().when('employee_type', {
        is: 'Other',
        then: Yup.string()
          .min(2, 'Employment name must be at least 2 characters')
          .max(50, 'Employment name must be at most 50 characters')
          .required('Enter Employment Name'),
        otherwise: Yup.string().notRequired()
      }),

      last_name: Yup.string().min(2).max(50).required('Enter Last Name'),
      // designation: Yup.string().required("Please enter designation"),
      phone_number: Yup.string()
        .min(10, 'Phone Number must be 10')
        .max(10, 'Phone Number must be 10')
        .required('Enter Phone Number')
      // email: Yup.string()
      //   .email("Invalid Email Formate")
      //   .required("Email is required"),
    }),
    onSubmit: values => {
      let shaw = Object.entries(validation.values)
      let filter_value = shaw.filter(v => v[1] === '' || v[1] === 0)
      let map_value = filter_value.map(m => m[0])
      let all_value = map_value[0]
      let fields1 = ['phone_number', 'last_name', 'first_name']
      if ((aadhar_number === '' || aadhar_number === null) && isupdating) {
        setaadhar_number_err(true)
        document.getElementById('user_info').scrollIntoView()
      } else if (
        (aadhar_number !== '' || aadhar_number !== null) &&
        aadhar_number?.length !== 12 &&
        location?.state === null &&
        isupdating
      ) {
        setaadhar_err(true)
        document.getElementById('user_info').scrollIntoView()
      } else if (joined_date === '' && isupdating) {
        setjoined_date_err(true)
        document.getElementById('user_info').scrollIntoView()
      } else if ((title_is === '' || title_is === null) && isupdating) {
        settitle_error(true)
        document.getElementById('user_info').scrollIntoView()
      } else if (fields1.includes(all_value)) {
        document.getElementById('user_info').scrollIntoView()
      } else if (usernamee === '' && isupdating) {
        setusername_err(true)
        document.getElementById('user_info').scrollIntoView()
      } else if (email === '') {
        setemail_err(true)
        document.getElementById('user_info').scrollIntoView()
      } else if (
        pf_no !== '' &&
        pf_no !== null &&
        pf_no?.length !== 22 &&
        isupdating
      ) {
        setpf_no_err(true)
        document.getElementById('user_info').scrollIntoView()
      } else if (
        uan_no !== '' &&
        pf_no !== null &&
        uan_no?.length !== 12 &&
        isupdating
      ) {
        setuan_no_err(true)
        document.getElementById('user_info').scrollIntoView()
      } else if (
        esic_no !== '' &&
        pf_no !== null &&
        esic_no?.length !== 17 &&
        isupdating
      ) {
        setesic_no_err(true)
        document.getElementById('user_info').scrollIntoView()
      } else if (org === '' && isupdating) {
        setorg_error(true)
        document.getElementById('workinfo').scrollIntoView()
      } else if (home_branch === '' && isupdating) {
        sethome_branch_err(true)
        document.getElementById('workinfo').scrollIntoView()
      } else if (employment_type === '') {
        setemp_type_err(true)
        document.getElementById('emp_type').scrollIntoView()
      } else if (user_department_list2.length === 0) {
        setuser_department_err(true)
        document.getElementById('deptt').scrollIntoView()
      } else if (designation === '') {
        setdesignation_err(true)
        document.getElementById('desig').scrollIntoView()
      } else if (role === '') {
        setrole_err(true)
        document.getElementById('rol').scrollIntoView()
      } else if ((cm === '' || cm === null) && isupdating) {
        setcm_error(true)
        document.getElementById('workinfo').scrollIntoView()
      } else if (row[row.length - 1].some(some => some === '') && isupdating) {
        let emptyRow = row.length
        setsalary_info_error(true)
        setsalary_info_text(`Fill All Salary Info in ${emptyRow} Row`)
      } else {
        isupdating ? Update_Invite_user(values) : invite_user(values)
      }
    }
  })

  // add Another
  let dimension_list = [salary_type, amount]
  const [row, setrow] = useState([dimension_list])
  console.log('row is', row)
  const addSalary = () => {
    let new_row = [['', ''], '']
    setrow([...row, new_row])
    setrefresh(!refresh)
  }

  const deleteSalary = item => {
    setsalary_type('salary_type')
    setamount('amount')
    // setremarks("remarks");

    let temp = [...row]
    // let temp_2 = [...gst_id_list];
    const index = temp.indexOf(item)
    if (index > -1) {
      temp.splice(index, 1)
      // temp_2.splice(index, 1);
    }
    setrow(temp)
    // setgst_id_list(temp_2);
  }

  const getOrganization = async () => {
    let org_name = []
    try {
      const response = await axios.get(
        ServerAddress +
          `organization/get_organization/?p=${org_page}&records=${10}&name_search=${org_search_item}&user_id=${user_id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
      if (response.data.next === null) {
        setorg_loaded(false)
      } else {
        setorg_loaded(true)
      }
      if (response.data.results.length > 0) {
        if (org_page === 1) {
          org_name = response.data.results.map(v => [
            v.pan_no,
            toTitleCase(v.name)
          ])
        } else {
          org_name = [
            ...org_list,
            ...response.data.results.map(v => [v.pan_no, toTitleCase(v.name)])
          ]
        }
      }
      setorg_count(org_count + 2)
      setorg_list(org_name)
    } catch (err) {
      alert(`Error Occur in Get Organisation Name, ${err}`)
    }
  }

  const getBranches = (org_id, filter_by) => {
    let temp3 = []
    axios
      .get(
        ServerAddress +
          `master/get_org_branch/?&p=${page}&records=${10}&branch_search=${search_branch}` +
          '&org_id=' +
          org_id +
          '&filter_by=' +
          filter_by,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
      .then(response => {
        if (response.data.next === null) {
          setbranch_loaded(false)
        } else {
          setbranch_loaded(true)
        }

        if (response.data.results.length > 0) {
          if (page === 1) {
            temp3 = response.data.results.map(v => [
              v.id,
              toTitleCase(v.branch_name)
            ])
          } else {
            temp3 = [
              ...home_branch_list,
              ...response.data.results.map(v => [
                v.id,
                toTitleCase(v.branch_name)
              ])
            ]
          }
          setbranch_count(branch_count + 2)
          sethome_branch_list(temp3)
        } else {
          sethome_branch_list([])
        }
      })
      .catch(err => {
        alert(`Error Occur in Get`, err)
      })
  }

  // This function is used to Get Department
  const get_department = async val => {
    let depart = []
    try {
      const response = await axios.get(
        ServerAddress +
          `organization/get_department_exculd/?p=${user_department_page}&records=${10}&search=${user_department_search}&data=${val}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
      if (response.data.next === null) {
        setuser_department_loaded(false)
      } else {
        setuser_department_loaded(true)
      }
      if (response.data.results.length > 0) {
        if (user_department_page === 1) {
          depart = response.data.results.map(v => [v.id, toTitleCase(v.name)])
        } else {
          depart = [
            ...user_department_list,
            ...response.data.results.map(v => [v.id, toTitleCase(v.name)])
          ]
        }
        setuser_department_count(user_department_count + 2)
        setuser_department_list(depart)
      } else {
        setuser_department_list([])
      }
    } catch (err) {
      toast.error(`Error Occur in Get Departments, ${err}`, {
        position: 'bottom-right',
        autoClose: 5000
      })
    }
  }

  const get_user_department = id => {
    let branch_temp = []
    let data = []
    axios
      .get(ServerAddress + `ems/get_user_department/?user_id=${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then(response => {
        data = response.data.user_department
        if (data.length > 0) {
          branch_temp = data
            .filter(v => v.user_department !== null)
            .map(v => [v.user_department, toTitleCase(v.user_department__name)])
          setup_department_list(branch_temp)
          setuser_department_list2(branch_temp)
        }
      })
      .catch(err => {
        toast.error(`Error Occur In Get User Department, ${err}`, {
          position: 'bottom-right',
          autoClose: 5000
        })
      })
  }

  useEffect(() => {
    if (user !== '') {
      get_user_department(user.id)
    }
  }, [user])

  useLayoutEffect(() => {
    get_department('all')
  }, [user_department_page, user_department_search])

  const getDesignation = () => {
    let temp3 = []
    axios
      .get(
        ServerAddress +
          `ems/Get-Designation/?search=${designation_search}&p=${designation_page}&records=${10}&filter_designation=${user_department_id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
      .then(response => {
        if (response.data.next === null) {
          setdesignation_loaded(false)
        } else {
          setdesignation_loaded(true)
        }

        if (response.data.results.length > 0) {
          console.log(response)
          if (user_department_page === 1) {
            temp3 = response.data.results.map(v => [
              v.id,
              toTitleCase(v.designation)
            ])
          } else {
            temp3 = [
              ...user_department_page,
              ...response.data.results.map(v => [
                v.id,
                toTitleCase(v.designation)
              ])
            ]
          }
          setdesignation_count(designation_count + 2)
          setDesignationNameList(temp3)
        } else {
          setDesignationNameList([])
        }
      })
      .catch(err => {
        alert(`Error Occur in Get`, err)
      })
  }

  const getrole = () => {
    let temp3 = []
    axios
      .get(
        ServerAddress +
          `ems/get_role/?search=${search_role}&p=${page_role}&records=${10}&filter_role=${designation_id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
      .then(response => {
        console.log('response', response)
        if (response.data.next === null) {
          set_role_loaded(false)
        } else {
          set_role_loaded(true)
        }

        if (response.data.results.length > 0) {
          console.log(response)
          if (page_role === 1) {
            temp3 = response.data.results.map(v => [v.id, toTitleCase(v.role)])
          } else {
            temp3 = [
              ...page_role,
              ...response.data.results.map(v => [v.id, toTitleCase(v.role)])
            ]
          }
          set_role_count(role_count + 2)
          setroleNameList(temp3)
        } else {
          setroleNameList([])
        }
      })
      .catch(err => {
        alert(`Error Occur in Get Role`, err)
      })
  }
  const get_department_salary = async () => {
    let depart = []
    try {
      const response = await axios.get(
        ServerAddress +
          `ems/get_salarycomponent/?p=${salary_component_page}&records=${10}&search=${salary_component_search}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
      if (response.data.next === null) {
        setsalary_component_loaded(false)
      } else {
        setsalary_component_loaded(true)
      }
      if (response.data.results.length > 0) {
        if (salary_component_page === 1) {
          depart = response.data.results.map(v => [v.id, toTitleCase(v.name)])
        } else {
          depart = [
            ...salary_component_list,
            ...response.data.results.map(v => [v.id, toTitleCase(v.name)])
          ]
        }
      }
      setsalary_component_count(salary_component_count + 2)
      setsalary_component_list(depart)
    } catch (err) {
      alert(`Error Occur in Get Departments, ${err}`)
    }
  }

  const duplicate = async () => {
    try {
      const response = await axios.post(
        ServerAddress + 'ems/check_User_Duplication/',
        {
          aadhar_number: aadhar_number,
          username: usernamee,
          email: email
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      console.log('response is', response)
      if (
        aadhar_number?.length === 12 &&
        response.data.message === 'Duplicate Aadhar number already exists'
      ) {
        setduplicate_aadhar_err(true)
        dispatch(setShowAlert(true))
        dispatch(setDataExist(`Aadhar number"${aadhar_number}" already exists`))
        dispatch(setAlertType('warning'))
      } else if (
        aadhar_number?.length === 12 &&
        response.data.message === 'No duplicates found'
      ) {
        setduplicate_aadhar_err(false)
      }
      if (
        usernamee !== '' &&
        response.data.message === 'Duplicate username already exists'
      ) {
        setdup_username_err(true)
        dispatch(setShowAlert(true))
        dispatch(setDataExist(`Username "${usernamee}" already exists`))
        dispatch(setAlertType('warning'))
      } else if (
        usernamee !== '' &&
        response.data.message === 'No duplicates found'
      ) {
        setdup_username_err(false)
      }

      if (
        email !== '' &&
        response.data.message === 'Duplicate email already exists'
      ) {
        setdup_email_err(true)
        dispatch(setShowAlert(true))
        dispatch(setDataExist(`Email "${email}" already exists`))
        dispatch(setAlertType('warning'))
      } else if (
        email !== '' &&
        response.data.message === 'No duplicates found'
      ) {
        setdup_email_err(false)
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert('Unauthorized: Please check your access token.')
      } else {
        alert(
          `Error Happened while posting Username, Aadhar, email: ${err.message}`
        )
      }
    }
  }

  useEffect(() => {
    get_department_salary()
  }, [salary_component_page, salary_component_search])

  useEffect(() => {
    getOrganization()
  }, [org_page, org_search_item])

  useEffect(() => {
    let timeoutId
    if (org_id !== '') {
      timeoutId = setTimeout(() => {
        getBranches(org_id, 'organization')
      }, 1)
    } else {
      getBranches()
    }
    return () => clearTimeout(timeoutId)
  }, [page, search_branch, org_id])

  useEffect(() => {
    if (user_department_id.length > 0) {
      getDesignation()
    }
  }, [designation_search, designation_page, user_department_id])

  useEffect(() => {
    if (designation_id !== null && designation_id !== '') {
      getrole()
    }
  }, [search_role, page_role, designation_id])

  // useEffect(() => {
  //   if (user_department_id !== departmentloaded) {
  //     setrole_id("")
  //     setrole("")
  //     setroleNameList("")
  //     setdesignation("")
  //     setdesignation_id("")
  //   }
  // }, [departmentloaded, user_department_id])

  // useEffect to handle changes based on designationLoaded and designation_id
  useEffect(() => {
    if (designationLoaded !== designation_id) {
      setroleNameList('')
      setrole_id('')
      setrole('')
    }
  }, [designationLoaded, designation_id])

  // useEffect to log the state changes
  useEffect(() => {
    console.log('roleNameList==', roleNameList)
  }, [roleNameList])

  // useEffect(() => {
  //   if (aadhar_number?.length === 12 && aadhar_number !=="" && location.state === null) {
  //     duplicate()
  //   }
  // },[aadhar_number])

  // useEffect(() => {
  //   if ( email !=="" && location.state === null ) {
  //     duplicate()
  //   }
  // },[email])
  // useEffect(() => {
  //   if (usernamee !== "" && location.state === null ) {
  //     duplicate()
  //   }
  // },[usernamee])

  const invite_user = values => {
    let department_id = user_department_list2.map(v => v[0])
    let department_name = user_department_list2.map(v => v[1].toUpperCase())

    let department_id_list = [...new Set(department_id.map(v => `${v}`))].map(
      v => parseInt(v.split(','))
    )

    let department_name_list = [
      ...new Set(department_name.map(v => v.split(',').map(s => s.trim())))
    ].flat()
    let department_name_string = department_name_list.join(', ')

    setshow_loader(true)

    axios
      .post(
        ServerAddress + 'ems/invite_user/',
        {
          email: email.toLowerCase(),
          mobilenumber: values.phone_number,
          firstname: toTitleCase(values.first_name).toUpperCase(),
          middlename: values?.middle_name
            ? toTitleCase(values.middle_name).toUpperCase()
            : '',
          other_employement: toTitleCase(
            values.other_employement
          ).toUpperCase(),
          lastname: toTitleCase(values.last_name).toUpperCase(),
          designation: designation_id,
          designation_name: designation,
          employment_type: employment_type.toUpperCase(),
          role: role_id,
          role_name: role,
          reporting_manager: User_id ? User_id : null,
          user_department: department_id_list,
          bkp_user_department: department_name_string
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(function (resp) {
        console.log('resp--', resp)
        setshow_loader(false)
        if (resp.status === 201) {
          dispatch(setShowAlert(true))
          dispatch(setDataExist(`${usernamee} Added sucessfully`))
          dispatch(setAlertType('success'))
          navigate('/ems/users')
          send_msg(email)
        } else if (
          resp.data.Response ===
          'User with this Mobile Number or Email is already exists'
        ) {
          dispatch(setShowAlert(true))
          dispatch(
            setDataExist(
              `This Mobile Number "${values.phone_number}" already exist .Please Try Another Mobile  Number`
            )
          )
          dispatch(setAlertType('danger'))
          document.getElementById('user_info').scrollIntoView()
        } else if (resp.data.Response === 'Duplicate Email Found') {
          dispatch(setShowAlert(true))
          dispatch(
            setDataExist(
              `This Email "${email}" already exist .Please Try Another Email`
            )
          )
          dispatch(setAlertType('danger'))
          document.getElementById('user_info').scrollIntoView()
        }
      })
      .catch(err => {
        setshow_loader(false)
        console.log(`error occur while adding,${err}`)
      })
  }

  const Update_Invite_user = values => {
    axios
      .put(
        ServerAddress + 'ems/Update-invite-user/' + invite_data_id,
        {
          aadhar_number: aadhar_number,
          username: usernamee.toLowerCase(),
          email: email.toLowerCase(),
          mobilenumber: values.phone_number,
          firstname: toTitleCase(values.first_name).toUpperCase(),
          other_employement: toTitleCase(
            values.other_employement
          ).toUpperCase(),
          middlename: values.middle_name
            ? toTitleCase(values.middle_name).toUpperCase()
            : '',
          lastname: toTitleCase(values.last_name).toUpperCase(),
          title: title_is.toUpperCase(),
          checker_maker: cm.toUpperCase(),
          organization_id: org_id,
          organization_name: org.toUpperCase(),
          bkp_organization: org.toUpperCase(),
          home_branch: home_branch_id,
          home_branch_name: home_branch,
          employment_type: employment_type.toUpperCase(),
          department: user_department_id,
          department_name: user_department.toUpperCase(),
          designation: values.designation
            ? toTitleCase(values.designation).toUpperCase()
            : '',
          get_hr_email: is_creadentials,
          hr_email: values.hr_email,
          salary_info: row,
          pf_no: pf_no,
          uan_no: uan_no,
          esic_no: esic_no
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
      .then(response => {
        if (response.data.status === 'success') {
          dispatch(setShowAlert(true))
          dispatch(setAlertType('success'))
          dispatch(
            setDataExist(`Invite User ${usernamee} Updated Successfully`)
          )
          navigate('/ems/users')
        }
      })
      .catch(error => {
        alert(`Error While Updating Invite User ${error}`)
      })
  }

  const send_msg = (email, password) => {
    const axios = require('axios')

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const data = {
      token: 'a8u6imzxp5jbfy98',
      to: validation.values.phone_number,
      body: `Welcome to the team! We're excited to have you on board at Etechcube LLP.\n\n Your skills and expertise will be a great asset to our organization. Please go through this company URL: ${`http://dhtc.etechcube.com/signin`} and complete the onboarding process. If you have any questions, feel free to reach out to your HR and ${`info@dhtc.com`}.\n\n We're here to support you as you settle into your role. Let's work together to achieve great things!\n\n Please Use below mentioned credentials: \n\nEmail: ${email}\nPassword: ${password}`
    }

    axios
      .post(
        'https://api.ultramsg.com/instance52709/messages/chat',
        data,
        config
      )
      .then(response => {
        if (response.data.message === 'ok') {
          alert('Message Sent Successfully')
        }
        console.log(response.data)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    try {
      let invite = location.state.user
      setisupdating(true)
      setinvite_data(invite)
      setinvite_data_id(invite.id)
      setaadhar_number(invite.aadhar_number)
      setusernamee(invite.username)
      setemail(invite.email)
      settitle_is(toTitleCase(invite.title))
      setcm(toTitleCase(invite.checker_maker))
      setjoined_date(invite.joined_date)
      setorg(toTitleCase(invite.organization_name))
      setorg_id(invite.organization_id)
      sethome_branch(toTitleCase(invite.home_branch_name))
      sethome_branch_id(invite.home_branch)
      setupdate_salary_info(invite.user_salary)
      setemployment_type(invite.employment_type)
      setdepartmentloaded(invite.department)
      setdesignationLoaded(invite.designation)
      setpf_no(invite.pf_no)
      setuan_no(invite.uan_no)
      setesic_no(invite.esic_no)
      setis_creadentials(invite.get_hr_email)
    } catch (error) {}
  }, [])

  useEffect(() => {
    if (isupdating) {
      if (update_salary_info.length !== 0) {
        let temp = []
        let temp_list = []
        temp = update_salary_info

        for (let i = 0; i < update_salary_info.length; i++) {
          temp_list.push([
            [temp[i].salarycomponent, temp[i].salary_component_name],
            temp[i].amount,
            temp[i].id,
            temp[i].employee
          ])
        }
        setrow(temp_list)
      }
    }
  }, [isupdating])

  useEffect(() => {
    if (joined_date !== '' && isupdating) {
      setjoined_date_err(false)
    }
    if (title_is !== '' && isupdating) {
      settitle_error(false)
    }
    if (cm !== '' && isupdating) {
      setcm_error(false)
    }
    if (org !== '' && isupdating) {
      setorg_error(false)
    }
    if (home_branch !== '' && isupdating) {
      sethome_branch_err(false)
    }
    if (employment_type !== '') {
      setemp_type_err(false)
    }
    if (user_department_list2.length !== 0) {
      setuser_department_err(false)
    }
    if (designation !== '') {
      setdesignation_err(false)
    }
    if (role !== '') {
      setrole_err(false)
    }
    if (row[row.length - 1].every(e => e !== '') && isupdating) {
      setsalary_info_error(false)
    }
    if (
      aadhar_number !== '' &&
      aadhar_number?.length !== 12 &&
      location.state === null &&
      isupdating
    ) {
      setaadhar_err(true)
    } else {
      setaadhar_err(false)
    }

    if (pf_no === '' || pf_no?.length === 22) {
      setpf_no_err(false)
    }
    if (uan_no === '' || uan_no?.length === 12) {
      setuan_no_err(false)
    }
    if (esic_no === '' || esic_no?.length === 17) {
      setesic_no_err(false)
    }
  }, [
    joined_date,
    title_is,
    org,
    home_branch,
    employment_type,
    user_department,
    dimension_list,
    cm,
    pf_no,
    uan_no,
    esic_no
  ])

  useEffect(() => {
    if (aadhar_number !== '') {
      setaadhar_number_err(false)
    }
    if (aadhar_number?.length === 12) {
      setaadhar_err(false)
    }
    if (usernamee !== '') {
      setusername_err(false)
    }
    if (email !== '') {
      setemail_err(false)
    }
  }, [email, aadhar_number, usernamee])

  // to set current date in joined date
  useEffect(() => {
    let date = new Date()
    let added_date_time =
      String(date.getDate()).length === 1
        ? '0' + String(date.getDate())
        : String(date.getDate())
    let month =
      String(date.getMonth() + 1).length === 1
        ? '0' + String(date.getMonth() + 1)
        : String(date.getMonth() + 1)
    let year = String(date.getFullYear())
    setjoined_date(`${year}-${month}-${added_date_time}`)
  }, [])

  const getUser = () => {
    let b_temp2 = []
    let b_data = []
    axios
      .get(
        ServerAddress +
          `ems/Get_User_Details/?search=${user_search}&p=${user_page}&records=${10}&exclude_user=${
            location?.state?.user?.id
          }`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
      .then(response => {
        b_data = response.data.results
        if (response.data.results.length > 0) {
          if (response.data.next === null) {
            setuser_loaded(false)
          } else {
            setuser_loaded(true)
          }
          if (user_page === 1) {
            b_temp2 = response.data.results.map(v => [
              v.id,
              `${toTitleCase(v?.firstname || '')} ${toTitleCase(
                v?.middlename || ''
              )} ${toTitleCase(v?.lastname || '')}-${v?.employee_id || ''}`,
              v.home_branch,
              v.joined_date
            ])
          } else {
            b_temp2 = [
              ...User_list,
              ...response.data.results.map(v => [
                v.id,
                `${toTitleCase(v?.firstname || '')} ${toTitleCase(
                  v?.middlename || ''
                )} ${toTitleCase(v?.lastname || '')}-${v?.employee_id || ''}`,
                v.home_branch,
                v.joined_date
              ])
            ]
          }
        }
        setuser_count(user_count + 2)
        setUser_list(b_temp2)
      })
      .catch(err => {
        console.warn(`Error Occur in Get ${err}`)
      })
  }

  useEffect(() => {
    getUser()
  }, [user_page, user_search])

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          let shaw = Object.entries(validation.values)
          let filter_value = shaw.filter(v => v[1] === '' || v[1] === 0)
          let map_value = filter_value.map(m => m[0])
          let all_value = map_value[0]
          let fields1 = ['phone_number', 'last_name', 'first_name']
          if (aadhar_number === '' && isupdating) {
            setaadhar_number_err(true)
            document.getElementById('user_info').scrollIntoView()
          }
          if (
            aadhar_number !== '' &&
            aadhar_number?.length !== 12 &&
            location?.state === null &&
            isupdating
          ) {
            setaadhar_err(true)
            document.getElementById('user_info').scrollIntoView()
          }
          if (joined_date === '' && isupdating) {
            setjoined_date_err(true)
            document.getElementById('user_info').scrollIntoView()
          }
          if ((title_is === '' || title_is === null) && isupdating) {
            settitle_error(true)
            document.getElementById('user_info').scrollIntoView()
          }
          if (fields1.includes(all_value)) {
            document.getElementById('user_info').scrollIntoView()
          }
          if (usernamee === '' && isupdating) {
            setusername_err(true)
            document.getElementById('user_info').scrollIntoView()
          }
          if (email === '') {
            setemail_err(true)
            document.getElementById('user_info').scrollIntoView()
          }
          if (org === '' && isupdating) {
            setorg_error(true)
            document.getElementById('workinfo').scrollIntoView()
          }
          if (home_branch === '' && isupdating) {
            sethome_branch_err(true)
            document.getElementById('workinfo').scrollIntoView()
          }
          if (employment_type === '') {
            setemp_type_err(true)
            document.getElementById('emp_type').scrollIntoView()
          }
          if (user_department_list2.length === 0) {
            setuser_department_err(true)
            document.getElementById('deptt').scrollIntoView()
          }
          if (designation === '') {
            setdesignation_err(true)
            document.getElementById('desig').scrollIntoView()
          }
          if (role === '') {
            setrole_err(true)
            document.getElementById('rol').scrollIntoView()
          }
          if ((cm === '' || cm === null) && isupdating) {
            setcm_error(true)
            document.getElementById('workinfo').scrollIntoView()
          }
          if (row[row.length - 1].some(some => some === '') && isupdating) {
            let emptyRow = row.length
            setsalary_info_error(true)
            setsalary_info_text(`Fill All Salary Info in ${emptyRow} Row`)
          }
          validation.handleSubmit(e.values)
          return false
        }}
      >
        {/* loader for avoid multipule click */}
        {show_loader ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: '999'
            }}
            aria-labelledby='contained-modal-title-vcenter'
          >
            <div className='loader-container'>
              <ColorRing
                visible={true}
                height='160'
                width='160'
                ariaLabel='blocks-loading'
                wrapperStyle={{}}
                wrapperClass='blocks-wrapper'
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
            </div>
          </div>
        ) : null}

        {/* // user basic info */}
        <div className='m-4'>
          <PageTitle
            page={isupdating ? 'Update Invite User' : 'Add Invite User'}
          />
          <Title
            title={isupdating ? 'Update Invite User' : 'Add Invite User'}
            parent_title='Ems'
          />
        </div>

        <div className='m-4' id='user_info'>
          <Col lg={12}>
            <Card className='shadow bg-white rounded'>
              <CardTitle className='mb-1 header'>
                <div className='header-text-icon header-text'>
                  <div>User info</div>
                  <IconContext.Provider
                    value={{
                      className: 'header-add-icon'
                    }}
                  ></IconContext.Provider>
                </div>
              </CardTitle>
              <CardBody>
                <Row>
                  {isupdating && (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>Aadhar Number*</Label>
                        <Input
                          onChange={e => {
                            const inputValue = e.target.value
                            if (inputValue.length <= 12) {
                              setaadhar_number(inputValue)
                            }
                          }}
                          onBlur={duplicate}
                          value={aadhar_number}
                          invalid={
                            aadhar_number_err ||
                            aadhar_err ||
                            duplicate_aadhar_err
                          }
                          type='number'
                          min={0}
                          maxLength={12}
                          name='aadhar_number'
                          className='form-control-md'
                          id='input'
                          placeholder='Enter Aadhar Number'
                          disabled={isupdating}
                        />
                        {aadhar_number_err && (
                          <FormFeedback type='invalid'>
                            Aadhar Number is required
                          </FormFeedback>
                        )}
                        {aadhar_err && (
                          <FormFeedback type='invalid'>
                            Aadhar Number must be 12 digits
                          </FormFeedback>
                        )}
                        {duplicate_aadhar_err &&
                          aadhar_number?.length === 12 &&
                          location.state === null && (
                            <FormFeedback type='invalid'>
                              Aadhar Number already exists
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                  )}

                  {isupdating && (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>Joined Date</Label>
                        <span className='mandatory'> *</span>
                        <Input
                          onBlur={validation.handleBlur}
                          type='date'
                          bsSize='sm'
                          min={0}
                          name='joined_date'
                          className='form-control-md'
                          id='input'
                          value={joined_date}
                          onChange={event => {
                            setjoined_date(event.target.value)
                          }}
                          invalid={joined_date_err}
                        />
                        {joined_date_err && (
                          <FormFeedback typeof='invalid'>
                            Joined date is required
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                  )}

                  {isupdating && (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>Title</Label>
                        <span className='mandatory'> *</span>
                        <NSearchInput
                          data_list={title_list}
                          data_item_s={title_is}
                          set_data_item_s={settitle_is}
                          show_search={false}
                          error_message={'Please Select Title'}
                          error_s={title_error}
                        />
                      </div>
                    </Col>
                  )}

                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-3'>
                      <Label className='header-child'>
                        First Name
                        <span className='mandatory'> *</span>
                      </Label>
                      <Input
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.first_name || ''}
                        invalid={
                          validation.touched.first_name &&
                          validation.errors.first_name
                        }
                        type='text'
                        name='first_name'
                        className='form-control-md'
                        id='input'
                        placeholder='Enter First Name'
                        autoComplete='off'
                      />
                      {validation.touched.first_name &&
                      validation.errors.first_name ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.first_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-3'>
                      <Label className='header-child'>Middle Name</Label>
                      <Input
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.middle_name || ''}
                        // invalid={
                        //   validation.touched.middle_name &&
                        //   validation.errors.middle_name
                        // }
                        type='text'
                        name='middle_name'
                        className='form-control-md'
                        id='input'
                        placeholder='Enter Middle Name'
                        autoComplete='off'
                      />
                      {/* {validation.touched.middle_name &&
                      validation.errors.middle_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.middle_name}
                        </FormFeedback>
                      ) : null} */}
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-3'>
                      <Label className='header-child'>
                        Last Name <span className='mandatory'>*</span>
                      </Label>
                      <Input
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.last_name || ''}
                        invalid={
                          validation.touched.last_name &&
                          validation.errors.last_name
                        }
                        type='text'
                        name='last_name'
                        className='form-control-md'
                        id='input'
                        placeholder='Enter Last Name'
                        autoComplete='off'
                      />
                      {validation.touched.last_name &&
                      validation.errors.last_name ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.last_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  {isupdating && (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>
                          Username <span className='mandatory'>*</span>
                        </Label>
                        <Input
                          onChange={e => {
                            setusernamee(e.target.value)
                          }}
                          onBlur={duplicate}
                          value={usernamee || ''}
                          invalid={username_err || dup_username_err}
                          type='text'
                          name='username'
                          bsSize='sm'
                          className='form-control-md'
                          id='input'
                          placeholder='Enter User Name'
                          disabled={isupdating}
                          autoComplete='off'
                        />
                        {username_err && (
                          <FormFeedback type='invalid'>
                            Username is required
                          </FormFeedback>
                        )}
                        {dup_username_err === true && (
                          <FormFeedback type='invalid'>
                            Username already exists
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                  )}

                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-3'>
                      <Label className='header-child'>
                        Phone Number <span className='mandatory'>*</span>
                      </Label>
                      <Input
                        onChange={e => {
                          const inputValue = e.target.value
                          if (inputValue.length <= 10) {
                            validation.handleChange(e)
                          }
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone_number || ''}
                        invalid={
                          validation.touched.phone_number &&
                          validation.errors.phone_number
                            ? true
                            : false
                        }
                        type='number'
                        min={0}
                        name='phone_number'
                        className='form-control-md'
                        id='input'
                        placeholder='Enter Phone Number'
                        autoComplete='off'
                        disabled={isupdating}
                      />
                      {validation.touched.phone_number &&
                      validation.errors.phone_number ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.phone_number}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-3'>
                      <Label className='header-child'>
                        Email <span className='mandatory'>*</span>
                      </Label>
                      <Input
                        onChange={e => {
                          setemail(e.target.value)
                        }}
                        onBlur={() => {
                          if (email !== '' && !email.includes('.com')) {
                            setemail_err(true)
                          } else {
                            duplicate()
                          }
                        }}
                        value={email.toLowerCase()}
                        invalid={email_err || dup_email_err}
                        type='email'
                        name='email'
                        className='form-control-md'
                        id='input'
                        placeholder='Enter Email'
                        disabled={isupdating}
                        autoComplete='off'
                      />
                      {email_err && (
                        <FormFeedback type='invalid'>
                          Valid Email is required
                        </FormFeedback>
                      )}
                      {dup_email_err && (
                        <FormFeedback type='invalid'>
                          Email is already exists
                        </FormFeedback>
                      )}
                    </div>
                  </Col>
                  {isupdating && (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>
                          Get Credentials on HR Email
                        </Label>
                        <div
                          onClick={() => setis_creadentials(!is_creadentials)}
                        >
                          {is_creadentials ? (
                            <FiCheckSquare size={18} />
                          ) : (
                            <FiSquare size={18} />
                          )}
                        </div>
                      </div>
                    </Col>
                  )}
                  {is_creadentials && isupdating ? (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>HR Email </Label>
                        <Input
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.hr_email || ''}
                          type='email'
                          name='hr_email'
                          className='form-control-md'
                          id='input'
                          placeholder='Enter HR Email'
                        />
                      </div>
                    </Col>
                  ) : null}

                  {isupdating && (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>PF No. </Label>
                        <Input
                          onChange={e => {
                            setpf_no(e.target.value)
                          }}
                          value={pf_no}
                          invalid={pf_no_err}
                          type='char'
                          maxLength={22}
                          name='pf_no'
                          className='form-control-md'
                          id='input'
                          placeholder='Enter PF No'
                          autoComplete='off'
                        />
                        <FormFeedback type='invalid'>
                          PF No. must have 22 digits
                        </FormFeedback>
                      </div>
                    </Col>
                  )}

                  {isupdating && (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>UAN No.</Label>
                        <Input
                          onChange={e => {
                            setuan_no(e.target.value)
                          }}
                          value={uan_no}
                          invalid={uan_no_err}
                          type='number'
                          maxLength={12}
                          name='uan_no'
                          className='form-control-md'
                          id='input'
                          placeholder='Enter UAN No'
                          autoComplete='off'
                        />
                        <FormFeedback type='invalid'>
                          UAN No. must have 12 digits
                        </FormFeedback>
                      </div>
                    </Col>
                  )}

                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-3' id='emp_type'>
                      <Label className='header-child'>Employment Type</Label>
                      <span className='mandatory'> *</span>
                      <NSearchInput
                        data_list={employment_type_list}
                        data_item_s={employment_type}
                        set_data_item_s={setemployment_type}
                        show_search={false}
                        error_message={'Select Employment Type'}
                        error_s={emp_type_err}
                      />
                    </div>
                  </Col>
                  {employment_type === 'Other' ? (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>
                          Employment Name <span className='mandatory'>*</span>
                        </Label>
                        <Input
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.other_employement || ''}
                          invalid={
                            validation.touched.other_employement &&
                            validation.errors.other_employement
                          }
                          type='text'
                          name='other_employement'
                          className='form-control-md'
                          id='input'
                          placeholder='Enter Employement Type'
                          autoComplete='off'
                        />
                        {validation.touched.other_employement &&
                        validation.errors.other_employement ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.other_employement}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-2'>
                      <Label className='header-child'>Reporting Manager</Label>
                      <SearchInput
                        data_list={User_list}
                        setdata_list={setUser_list}
                        data_item_s={User}
                        set_data_item_s={setUser}
                        set_id={setuser_id}
                        page={user_page}
                        setpage={setuser_page}
                        setsearch_item={setuser_search}
                        // error_s={user_err}
                        // error_message={"Select Any Reporting Manager"}
                        loaded={user_loaded}
                        count={user_count}
                        bottom={user_bottom}
                        setbottom={setuser_bottom}
                      />
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12}>
                    <Label className='header-child'>
                      Department
                      <span className='mandatory'> *</span>
                    </Label>
                    <TransferList
                      list_a={user_department_list}
                      setlist_a={setuser_department_list}
                      list_b={user_department_list2}
                      setlist_b={setuser_department_list2}
                      page={user_department_page}
                      setpage={setuser_department_page}
                      setsearch_item={setuser_department_search}
                      loaded={user_department_loaded}
                      count={user_department_count}
                      bottom={user_department_bottom}
                      setbottom={setuser_department_bottom}
                    />
                    {user_department_err ? (
                      <div style={{ color: '#f46a6a', fontSize: '10.4px' }}>
                        Select At Least One Department
                      </div>
                    ) : null}
                  </Col>

                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-2' id='desig'>
                      <Label className='header-child'>
                        Designation <span className='mandatory'>*</span>
                      </Label>
                      <SearchInput
                        data_list={designationNameList}
                        set_data_list={setDesignationNameList}
                        data_item_s={designation}
                        set_data_item_s={setdesignation}
                        set_id={setdesignation_id}
                        page={designation_page}
                        setpage={setdesignation_page}
                        error_message={'Select Any Designation'}
                        error_s={designation_err}
                        search_item={designation_search}
                        setsearch_item={setdesignation_search}
                        loaded={designation_loaded}
                        count={designation_count}
                        bottom={designation_bottom}
                        setbottom={setdesignation_bottom}
                      />
                    </div>
                  </Col>

                  <Col lg={4} md={6} sm={6}>
                    <div className='mb-2' id='rol'>
                      <Label className='header-child'>
                        Role <span className='mandatory'>*</span>
                      </Label>
                      <SearchInput
                        data_list={roleNameList}
                        set_data_list={setroleNameList}
                        data_item_s={role}
                        set_data_item_s={setrole}
                        set_id={setrole_id}
                        page={page_role}
                        setpage={setpage_role}
                        error_message={'Select Any Role'}
                        error_s={role_err}
                        search_item={search_role}
                        setsearch_item={setsearch_role}
                        loaded={role_loaded}
                        count={role_count}
                        bottom={role_bottom}
                        setbottom={setrole_bottom}
                      />
                    </div>
                  </Col>

                  {isupdating && (
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>ESIC No.</Label>
                        <Input
                          onChange={e => {
                            setesic_no(e.target.value)
                          }}
                          value={esic_no}
                          invalid={esic_no_err}
                          maxLength={17}
                          type='char'
                          name='esic_no'
                          className='form-control-md'
                          id='input'
                          placeholder='Enter ESIC No'
                          autoComplete='off'
                        />
                        <FormFeedback type='invalid'>
                          ESIC No. must have 17 digits
                        </FormFeedback>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </div>

        {/* // user work info */}
        {isupdating && (
          <div className='m-4'>
            <div className='mb-2 main-header' id='workinfo'></div>
            <Col lg={12}>
              <Card className='shadow bg-white rounded'>
                <CardTitle className='mb-1 header'>
                  <div className='header-text-icon header-text'>
                    <div>Work Info</div>
                    <IconContext.Provider
                      value={{
                        className: 'header-add-icon'
                      }}
                    ></IconContext.Provider>
                  </div>
                </CardTitle>
                <CardBody>
                  <Row>
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-2'>
                        <Label className='header-child'>
                          Organization Name
                        </Label>
                        <span className='mandatory'> *</span>
                        <SearchInput
                          data_list={org_list}
                          setdata_list={setorg_list}
                          data_item_s={org}
                          set_data_item_s={setorg}
                          set_id={setorg_id}
                          page={org_page}
                          setpage={setorg_page}
                          setsearch_item={setorg_search_item}
                          error_message={'Select Any Organization'}
                          error_s={org_error}
                          loaded={org_loaded}
                          count={org_count}
                          bottom={org_bottom}
                          setbottom={setorg_bottom}
                          disable_me={!user_detail.is_superuser}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-2'>
                        <Label className='header-child'>Home Branch</Label>
                        <span className='mandatory'> *</span>
                        <SearchInput
                          data_list={home_branch_list}
                          setdata_list={sethome_branch_list}
                          data_item_s={home_branch}
                          set_data_item_s={sethome_branch}
                          page={page}
                          setpage={setpage}
                          set_id={sethome_branch_id}
                          setsearch_item={setsearch_branch}
                          error_message={'Select Any Branch'}
                          error_s={home_branch_err}
                          loaded={branch_loaded}
                          count={branch_count}
                          bottom={branch_bottom}
                          setbottom={setbranch_bottom}
                          // disable_me={!user_detail.is_superuser && is_update}
                        />
                      </div>
                      {/* <div className="mt-1 error-text" color="danger">
                        {home_branch_err ? "Please Select Any Branch" : null}
                      </div> */}
                    </Col>

                    <Col lg={4} md={6} sm={6}>
                      <div className='mb-3'>
                        <Label className='header-child'>Is Checker/Maker</Label>
                        <span className='mandatory'> *</span>
                        <NSearchInput
                          data_list={cm_list}
                          data_item_s={cm}
                          set_data_item_s={setcm}
                          show_search={false}
                          error_message={'Please select Checker/Maker'}
                          error_s={cm_error}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </div>
        )}

        {/* // user salary */}
        {isupdating && (
          <div className='m-3' id='salary_details'>
            <Col lg={12}>
              <Card className='shadow bg-white rounded'>
                <CardTitle className='mb-1 header'>
                  <div className='header-text-icon header-text'>
                    Salary Info
                    <IconContext.Provider
                      value={{
                        className: 'header-add-icon'
                      }}
                    >
                      <div onClick={toggle_circle3}>
                        {circle_btn3 ? (
                          <MdRemoveCircleOutline />
                        ) : (
                          <MdAddCircleOutline />
                        )}
                      </div>
                    </IconContext.Provider>
                  </div>
                </CardTitle>
                {circle_btn3 ? (
                  <CardBody>
                    <Row>
                      <>
                        <Row className='hide'>
                          <Col lg={3} md={6} sm={6}>
                            <div className='mb-3'>
                              <Label className='header-child'>
                                Salary Type
                              </Label>
                              {row.map((item, index) => (
                                <div className='mb-3'>
                                  <MultiRowSearchInput
                                    data_list={salary_component_list}
                                    setdata_list={setsalary_component_list}
                                    data_item_s={row[index][0]}
                                    page={salary_component_page}
                                    setpage={setsalary_component_page}
                                    setsearch_txt={setsalary_component_search}
                                    refresh={refresh}
                                    setrefresh={setrefresh}
                                    idx={index}
                                    loaded={salary_component_loaded}
                                    count={salary_component_count}
                                    bottom={salary_component_bottom}
                                    setbottom={setsalary_component_bottom}
                                  />
                                </div>
                              ))}
                            </div>
                          </Col>

                          <Col lg={3} md={6} sm={6}>
                            <div className='mb-3'>
                              <Label className='header-child'>Amount</Label>
                              {row.map((item, index) => {
                                return (
                                  <Input
                                    min={0}
                                    key={index}
                                    value={item[1]}
                                    type='number'
                                    className='form-control-md'
                                    id='input'
                                    autoComplete='off'
                                    style={{ marginBottom: '15px' }}
                                    placeholder='Enter Amount '
                                    onChange={val => {
                                      item[1] = val.target.value
                                      setrefresh(!refresh)
                                    }}
                                  />
                                )
                              })}
                            </div>
                          </Col>

                          <Col lg={1}>
                            <div
                              className='mb-3'
                              style={{ textAlign: 'center' }}
                            >
                              {row.length > 1 ? (
                                <Label className='header-child'>Delete</Label>
                              ) : null}
                              {row.map((item, index) => (
                                <IconContext.Provider
                                  key={index}
                                  value={{
                                    className: 'icon multi-input'
                                  }}
                                >
                                  {row.length > 1 ? (
                                    <>
                                      {/* <div style={{ height: "14.5px" }}></div> */}
                                      <div
                                        onClick={() => {
                                          deleteSalary(item)
                                        }}
                                      >
                                        <MdDeleteForever
                                          style={{
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            marginBottom: '37px'
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </IconContext.Provider>
                              ))}
                            </div>
                          </Col>
                          {salary_info_error ? (
                            <div
                              style={{
                                color: '#f46a6a',
                                fontSize: '12px'
                                // marginBottom: "12px",
                              }}
                            >
                              {salary_info_text}
                            </div>
                          ) : null}
                        </Row>
                        <>
                          {row.length < 20 && (
                            <div style={{ margin: ' 0 0 20px 0' }}>
                              <span
                                className='link-text'
                                onClick={() => {
                                  if (
                                    row[row.length - 1].some(
                                      data => data === ''
                                    )
                                  ) {
                                    const emptyRow = row.length
                                    setsalary_info_error(true)
                                    setsalary_info_text(
                                      `Please Fill All Salary Info in ${emptyRow} Row`
                                    )
                                  } else {
                                    addSalary()
                                  }
                                }}
                              >
                                <IconContext.Provider
                                  value={{
                                    className: 'link-text'
                                  }}
                                >
                                  <MdAdd />
                                </IconContext.Provider>
                                Add Another Salary Info
                              </span>
                            </div>
                          )}
                        </>
                      </>
                    </Row>
                  </CardBody>
                ) : null}
              </Card>
            </Col>
          </div>
        )}

        <div className=' m-4'>
          <Col lg={12}>
            <div className='mb-1 footer_btn'>
              <button
                type='submit'
                className='btn btn-success'
                style={{ marginRight: '15px', width: '77px' }}
              >
                {isupdating ? 'Update' : 'Save'}
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  navigate('/ems/users')
                }}
              >
                Cancel
              </button>
            </div>
          </Col>
        </div>
      </Form>
    </div>
  )
}
export default UserInfo
